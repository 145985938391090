import React, { useState } from 'react';
import './switch-selector.css';

const Switch = ({ options, onValueChange }) => {
    const [selectedOption, setSelectedOption] = useState(options[0].value);

    const handleSwitchChange = (value) => {
        setSelectedOption(value);
        if (onValueChange) {
            onValueChange(value);
        }
    };

    return (
        <div className="switch-container">
            {options.map((option, index) => (
                <div
                    key={index}
                    className={`switch-option ${selectedOption === option.value ? 'selected' : ''}`}
                    onClick={() => handleSwitchChange(option.value)}
                >
                    {option.label}
                </div>
            ))}
        </div>
    );
};

export default Switch;