import React, {useEffect} from 'react'
import { MuiFileInput } from 'mui-file-input'
import CloseIcon from '@mui/icons-material/Close'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const InputFile = ({placeholder, file, setFile, isValid}) => {
    const MAX_FILE_SIZE = 10 * 1024 * 1024;
  

    const handleChange = (newFile) => {
        if (newFile && newFile.size > MAX_FILE_SIZE) {
            toast.error("El tamaño excede el límite permitido: 10 MB", {
                position: 'top-center',
                autoClose: 2500,
                style: {
                    fontFamily: 'Viga',
                    fontSize:'12px',
                    
                    height:'70px',
                    width: '350px',
                    
                },
            });
        } else {
            setFile(newFile);
        }
    };

    useEffect(() => {
        if (file) {
            console.log("Archivo seleccionado:", file);
        } else {
            console.log("No hay archivo seleccionado.");
        }
    }, [file]);

    return (
        <MuiFileInput  hideSizeText
                       isValid={isValid}
                       value={file}
                       onChange={handleChange}
                       placeholder={placeholder}
                       sx={{
                           '& .MuiInputBase-root': {
                               background: '#f4f7f6',
                               borderRadius: {xs: '30px', sm: '0.6vw'},
                               marginTop: {xs: '5vw', sm: '1vw'},
                               fontFamily: 'Viga',
                               height: {xs: '10vw', sm: '2.5vw'},
                               fontSize: {xs: '3.2vw', sm: '1vw'},
                               paddingLeft: {xs: '3vw', sm: '0.6vw'},
                               paddingRight: {xs: '1.5vw', sm: '0.5vw'},
                               boxShadow: '0.1vw 0.1vw 0.4vw 0px rgba(179,179,179,0.54)',
                           },
                           '& .MuiSvgIcon-root': {
                               fontSize: {xs: '5vw', sm: '1.5vw'},
                           },
                           '& .MuiTypography-root':{
                               fontFamily: 'Viga',
                               fontSize: {xs: '3.2vw', sm: '0.8vw'},
                               color: '#FFF',
                               fontWeight: 'normal',
                           },
                           '& .MuiInputAdornment-root':{
                               marginRight: {xs: '3vw', sm: '0.3vw'}
                           },
                           '& .MuiFileInput-placeholder':{
                               textWrap: 'nowrap',
                           },
                           '& span.MuiFileInput-placeholder':{
                               color: '#999999',
                           },
                       }}
                       InputProps={{
                           inputProps: {
                               accept:'.png, .jpeg, .pdf'
                           },
                           startAdornment: <AttachFileIcon fontSize="small"/>
                       }}

                       clearIconButtonProps={{
                           title: "Remove",
                           children: <CloseIcon fontSize="small" />
                       }}
                       

        />
        
    )
}

export default InputFile;