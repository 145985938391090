export const opcionesDepartamentos = [
  {
    value: 91,
    label: 'Amazonas',
    ciudades: [
      { value: 91.001, label: 'Leticia' },
      { value: 91.263, label: 'El Encanto' },
      { value: 91.405, label: 'La Chorrera' },
      { value: 91.407, label: 'La Pedrera' },
      { value: 91.43, label: 'La Victoria' },
      { value: 91.536, label: 'Puerto Arica' },
      { value: 91.54, label: 'Puerto Nariño' },
      { value: 91.669, label: 'Puerto Santander' },
      { value: 91.798, label: 'Tarapacá' },
      { value: 91.53, label: 'Puerto Alegría' },
      { value: 91.46, label: 'Miriti Paraná' },
    ]
  },
  {
    value: 5,
    label: 'Antioquia',
    ciudades: [
      { value: 5.001, label: 'Medellín' },
      { value: 5.002, label: 'Abejorral' },
      { value: 5.004, label: 'Abriaquí' },
      { value: 5.021, label: 'Alejandría' },
      { value: 5.03, label: 'Amagá' },
      { value: 5.031, label: 'Amalfi' },
      { value: 5.034, label: 'Andes' },
      { value: 5.036, label: 'Angelópolis' },
      { value: 5.038, label: 'Angostura' },
      { value: 5.04, label: 'Anorí' },
      { value: 5.044, label: 'Anza' },
      { value: 5.045, label: 'Apartadó' },
      { value: 5.051, label: 'Arboletes' },
      { value: 5.055, label: 'Argelia' },
      { value: 5.059, label: 'Armenia' },
      { value: 5.079, label: 'Barbosa' },
      { value: 5.088, label: 'Bello' },
      { value: 5.091, label: 'Betania' },
      { value: 5.093, label: 'Betulia' },
      { value: 5.101, label: 'Ciudad Bolívar' },
      { value: 5.107, label: 'Briceño' },
      { value: 5.113, label: 'Buriticá' },
      { value: 5.12, label: 'Cáceres' },
      { value: 5.125, label: 'Caicedo' },
      { value: 5.129, label: 'Caldas' },
      { value: 5.134, label: 'Campamento' },
      { value: 5.138, label: 'Cañasgordas' },
      { value: 5.142, label: 'Caracolí' },
      { value: 5.145, label: 'Caramanta' },
      { value: 5.147, label: 'Carepa' },
      { value: 5.15, label: 'Carolina' },
      { value: 5.154, label: 'Caucasia' },
      { value: 5.172, label: 'Chigorodó' },
      { value: 5.19, label: 'Cisneros' },
      { value: 5.197, label: 'Cocorná' },
      { value: 5.206, label: 'Concepción' },
      { value: 5.209, label: 'Concordia' },
      { value: 5.212, label: 'Copacabana' },
      { value: 5.234, label: 'Dabeiba' },
      { value: 5.237, label: 'Don Matías' },
      { value: 5.24, label: 'Ebéjico' },
      { value: 5.25, label: 'El Bagre' },
      { value: 5.264, label: 'Entrerrios' },
      { value: 5.266, label: 'Envigado' },
      { value: 5.282, label: 'Fredonia' },
      { value: 5.306, label: 'Giraldo' },
      { value: 5.308, label: 'Girardota' },
      { value: 5.31, label: 'Gómez Plata' },
      { value: 5.315, label: 'Guadalupe' },
      { value: 5.318, label: 'Guarne' },
      { value: 5.321, label: 'Guatapé' },
      { value: 5.347, label: 'Heliconia' },
      { value: 5.353, label: 'Hispania' },
      { value: 5.36, label: 'Itagui' },
      { value: 5.361, label: 'Ituango' },
      { value: 5.086, label: 'Belmira' },
      { value: 5.368, label: 'Jericó' },
      { value: 5.376, label: 'La Ceja' },
      { value: 5.38, label: 'La Estrella' },
      { value: 5.39, label: 'La Pintada' },
      { value: 5.4, label: 'La Unión' },
      { value: 5.411, label: 'Liborina' },
      { value: 5.425, label: 'Maceo' },
      { value: 5.44, label: 'Marinilla' },
      { value: 5.467, label: 'Montebello' },
      { value: 5.475, label: 'Murindó' },
      { value: 5.48, label: 'Mutatá' },
      { value: 5.483, label: 'Nariño' },
      { value: 5.49, label: 'Necoclí' },
      { value: 5.495, label: 'Nechí' },
      { value: 5.501, label: 'Olaya' },
      { value: 5.541, label: 'Peñol' },
      { value: 5.543, label: 'Peque' },
      { value: 5.576, label: 'Pueblorrico' },
      { value: 5.579, label: 'Puerto Berrío' },
      { value: 5.585, label: 'Puerto Nare' },
      { value: 5.591, label: 'Puerto Triunfo' },
      { value: 5.604, label: 'Remedios' },
      { value: 5.607, label: 'Retiro' },
      { value: 5.615, label: 'Rionegro' },
      { value: 5.628, label: 'Sabanalarga' },
      { value: 5.631, label: 'Sabaneta' },
      { value: 5.642, label: 'Salgar' },
      { value: 5.652, label: 'San Francisco' },
      { value: 5.656, label: 'San Jerónimo' },
      { value: 5.66, label: 'San Luis' },
      { value: 5.664, label: 'San Pedro' },
      { value: 5.667, label: 'San Rafael' },
      { value: 5.67, label: 'San Roque' },
      { value: 5.674, label: 'San Vicente' },
      { value: 5.679, label: 'Santa Bárbara' },
      { value: 5.69, label: 'Santo Domingo' },
      { value: 5.697, label: 'El Santuario' },
      { value: 5.736, label: 'Segovia' },
      { value: 5.761, label: 'Sopetrán' },
      { value: 5.789, label: 'Támesis' },
      { value: 5.79, label: 'Tarazá' },
      { value: 5.792, label: 'Tarso' },
      { value: 5.809, label: 'Titiribí' },
      { value: 5.819, label: 'Toledo' },
      { value: 5.837, label: 'Turbo' },
      { value: 5.842, label: 'Uramita' },
      { value: 5.847, label: 'Urrao' },
      { value: 5.854, label: 'Valdivia' },
      { value: 5.856, label: 'Valparaíso' },
      { value: 5.858, label: 'Vegachí' },
      { value: 5.861, label: 'Venecia' },
      { value: 5.885, label: 'Yalí' },
      { value: 5.887, label: 'Yarumal' },
      { value: 5.89, label: 'Yolombó' },
      { value: 5.893, label: 'Yondó' },
      { value: 5.895, label: 'Zaragoza' },
      { value: 5.665, label: 'San Pedro de Uraba' },
      { value: 5.042, label: 'Santafé de Antioquia' },
      { value: 5.686, label: 'Santa Rosa de Osos' },
      { value: 5.647, label: 'San Andrés de Cuerquía' },
      { value: 5.873, label: 'Vigía del Fuerte' },
      { value: 5.658, label: 'San José de La Montaña' },
      { value: 5.659, label: 'San Juan de Urabá' },
      { value: 5.148, label: 'El Carmen de Viboral' },
      { value: 5.649, label: 'San Carlos' },
      { value: 5.284, label: 'Frontino' },
      { value: 5.313, label: 'Granada' },
      { value: 5.364, label: 'Jardín' },
      { value: 5.756, label: 'Sonsón' },
    ]
  },
  {
    value: 81,
    label: 'Arauca',
    ciudades: [
      { value: 81.065, label: 'Arauquita' },
      { value: 81.22, label: 'Cravo Norte' },
      { value: 81.3, label: 'Fortul' },
      { value: 81.591, label: 'Puerto Rondón' },
      { value: 81.736, label: 'Saravena' },
      { value: 81.794, label: 'Tame' },
      { value: 81.001, label: 'Arauca' },
    ]
  },
  {
    value: 88,
    label: 'Archipiélago de San Andrés, Providencia y Santa Catalina',
    ciudades: [
      { value: 88.564, label: 'Providencia' },
      { value: 88.001, label: 'San Andrés' },
    ]
  },
  {
    value: 8,
    label: 'Atlántico',
    ciudades: [
      { value: 8.001, label: 'Barranquilla' },
      { value: 8.078, label: 'Baranoa' },
      { value: 8.141, label: 'Candelaria' },
      { value: 8.296, label: 'Galapa' },
      { value: 8.421, label: 'Luruaco' },
      { value: 8.433, label: 'Malambo' },
      { value: 8.436, label: 'Manatí' },
      { value: 8.549, label: 'Piojó' },
      { value: 8.558, label: 'Polonuevo' },
      { value: 8.634, label: 'Sabanagrande' },
      { value: 8.638, label: 'Sabanalarga' },
      { value: 8.675, label: 'Santa Lucía' },
      { value: 8.685, label: 'Santo Tomás' },
      { value: 8.758, label: 'Soledad' },
      { value: 8.77, label: 'Suan' },
      { value: 8.832, label: 'Tubará' },
      { value: 8.849, label: 'Usiacurí' },
      { value: 8.372, label: 'Juan de Acosta' },
      { value: 8.52, label: 'Palmar de Varela' },
      { value: 8.137, label: 'Campo de La Cruz' },
      { value: 8.606, label: 'Repelón' },
      { value: 8.573, label: 'Puerto Colombia' },
      { value: 8.56, label: 'Ponedera' },
    ]
  },
  {
    value: 11,
    label: 'Bogotá D.C.',
    ciudades: [
      { value: 11.001, label: 'Bogotá D.C.' },
    ]
  },
  {
    value: 13,
    label: 'Bolívar',
    ciudades: [
      { value: 13.006, label: 'Achí' },
      { value: 13.042, label: 'Arenal' },
      { value: 13.052, label: 'Arjona' },
      { value: 13.062, label: 'Arroyohondo' },
      { value: 13.14, label: 'Calamar' },
      { value: 13.16, label: 'Cantagallo' },
      { value: 13.188, label: 'Cicuco' },
      { value: 13.212, label: 'Córdoba' },
      { value: 13.222, label: 'Clemencia' },
      { value: 13.248, label: 'El Guamo' },
      { value: 13.43, label: 'Magangué' },
      { value: 13.433, label: 'Mahates' },
      { value: 13.44, label: 'Margarita' },
      { value: 13.458, label: 'Montecristo' },
      { value: 13.468, label: 'Mompós' },
      { value: 13.473, label: 'Morales' },
      { value: 13.49, label: 'Norosí' },
      { value: 13.549, label: 'Pinillos' },
      { value: 13.58, label: 'Regidor' },
      { value: 13.6, label: 'Río Viejo' },
      { value: 13.647, label: 'San Estanislao' },
      { value: 13.65, label: 'San Fernando' },
      { value: 13.657, label: 'San Juan Nepomuceno' },
      { value: 13.673, label: 'Santa Catalina' },
      { value: 13.683, label: 'Santa Rosa' },
      { value: 13.744, label: 'Simití' },
      { value: 13.76, label: 'Soplaviento' },
      { value: 13.78, label: 'Talaigua Nuevo' },
      { value: 13.81, label: 'Tiquisio' },
      { value: 13.836, label: 'Turbaco' },
      { value: 13.838, label: 'Turbaná' },
      { value: 13.873, label: 'Villanueva' },
      { value: 13.074, label: 'Barranco de Loba' },
      { value: 13.688, label: 'Santa Rosa del Sur' },
      { value: 13.3, label: 'Hatillo de Loba' },
      { value: 13.244, label: 'El Carmen de Bolívar' },
      { value: 13.667, label: 'San Martín de Loba' },
      { value: 13.03, label: 'Altos del Rosario' },
      { value: 13.655, label: 'San Jacinto del Cauca' },
      { value: 13.67, label: 'San Pablo de Borbur' },
      { value: 13.654, label: 'San Jacinto' },
      { value: 13.268, label: 'El Peñón' },
      { value: 13.001, label: 'Cartagena' },
      { value: 13.442, label: 'María la Baja' },
      { value: 13.62, label: 'San Cristóbal' },
      { value: 13.894, label: 'Zambrano' },
    ]
  },
  {
    value: 15,
    label: 'Boyacá',
    ciudades: [
      { value: 15.832, label: 'Tununguá' },
      { value: 15.476, label: 'Motavita' },
      { value: 15.189, label: 'Ciénega' },
      { value: 15.001, label: 'Tunja' },
      { value: 15.022, label: 'Almeida' },
      { value: 15.047, label: 'Aquitania' },
      { value: 15.051, label: 'Arcabuco' },
      { value: 15.09, label: 'Berbeo' },
      { value: 15.092, label: 'Betéitiva' },
      { value: 15.097, label: 'Boavita' },
      { value: 15.104, label: 'Boyacá' },
      { value: 15.106, label: 'Briceño' },
      { value: 15.109, label: 'Buena Vista' },
      { value: 15.114, label: 'Busbanzá' },
      { value: 15.131, label: 'Caldas' },
      { value: 15.135, label: 'Campohermoso' },
      { value: 15.162, label: 'Cerinza' },
      { value: 15.172, label: 'Chinavita' },
      { value: 15.176, label: 'Chiquinquirá' },
      { value: 15.18, label: 'Chiscas' },
      { value: 15.183, label: 'Chita' },
      { value: 15.185, label: 'Chitaraque' },
      { value: 15.187, label: 'Chivatá' },
      { value: 15.204, label: 'Cómbita' },
      { value: 15.212, label: 'Coper' },
      { value: 15.215, label: 'Corrales' },
      { value: 15.218, label: 'Covarachía' },
      { value: 15.223, label: 'Cubará' },
      { value: 15.224, label: 'Cucaita' },
      { value: 15.226, label: 'Cuítiva' },
      { value: 15.232, label: 'Chíquiza' },
      { value: 15.236, label: 'Chivor' },
      { value: 15.238, label: 'Duitama' },
      { value: 15.244, label: 'El Cocuy' },
      { value: 15.248, label: 'El Espino' },
      { value: 15.272, label: 'Firavitoba' },
      { value: 15.276, label: 'Floresta' },
      { value: 15.293, label: 'Gachantivá' },
      { value: 15.296, label: 'Gameza' },
      { value: 15.299, label: 'Garagoa' },
      { value: 15.317, label: 'Guacamayas' },
      { value: 15.322, label: 'Guateque' },
      { value: 15.325, label: 'Guayatá' },
      { value: 15.332, label: 'Güicán' },
      { value: 15.362, label: 'Iza' },
      { value: 15.367, label: 'Jenesano' },
      { value: 15.368, label: 'Jericó' },
      { value: 15.377, label: 'Labranzagrande' },
      { value: 15.38, label: 'La Capilla' },
      { value: 15.401, label: 'La Victoria' },
      { value: 15.425, label: 'Macanal' },
      { value: 15.442, label: 'Maripí' },
      { value: 15.455, label: 'Miraflores' },
      { value: 15.464, label: 'Mongua' },
      { value: 15.466, label: 'Monguí' },
      { value: 15.469, label: 'Moniquirá' },
      { value: 15.48, label: 'Muzo' },
      { value: 15.491, label: 'Nobsa' },
      { value: 15.494, label: 'Nuevo Colón' },
      { value: 15.5, label: 'Oicatá' },
      { value: 15.507, label: 'Otanche' },
      { value: 15.511, label: 'Pachavita' },
      { value: 15.514, label: 'Páez' },
      { value: 15.516, label: 'Paipa' },
      { value: 15.518, label: 'Pajarito' },
      { value: 15.522, label: 'Panqueba' },
      { value: 15.531, label: 'Pauna' },
      { value: 15.533, label: 'Paya' },
      { value: 15.542, label: 'Pesca' },
      { value: 15.55, label: 'Pisba' },
      { value: 15.572, label: 'Puerto Boyacá' },
      { value: 15.58, label: 'Quípama' },
      { value: 15.599, label: 'Ramiriquí' },
      { value: 15.6, label: 'Ráquira' },
      { value: 15.621, label: 'Rondón' },
      { value: 15.632, label: 'Saboyá' },
      { value: 15.638, label: 'Sáchica' },
      { value: 15.646, label: 'Samacá' },
      { value: 15.66, label: 'San Eduardo' },
      { value: 15.673, label: 'San Mateo' },
      { value: 15.686, label: 'Santana' },
      { value: 15.69, label: 'Santa María' },
      { value: 15.696, label: 'Santa Sofía' },
      { value: 15.72, label: 'Sativanorte' },
      { value: 15.723, label: 'Sativasur' },
      { value: 15.74, label: 'Siachoque' },
      { value: 15.753, label: 'Soatá' },
      { value: 15.755, label: 'Socotá' },
      { value: 15.757, label: 'Socha' },
      { value: 15.759, label: 'Sogamoso' },
      { value: 15.761, label: 'Somondoco' },
      { value: 15.762, label: 'Sora' },
      { value: 15.763, label: 'Sotaquirá' },
      { value: 15.764, label: 'Soracá' },
      { value: 15.774, label: 'Susacón' },
      { value: 15.776, label: 'Sutamarchán' },
      { value: 15.778, label: 'Sutatenza' },
      { value: 15.79, label: 'Tasco' },
      { value: 15.798, label: 'Tenza' },
      { value: 15.804, label: 'Tibaná' },
      { value: 15.808, label: 'Tinjacá' },
      { value: 15.81, label: 'Tipacoque' },
      { value: 15.814, label: 'Toca' },
      { value: 15.82, label: 'Tópaga' },
      { value: 15.822, label: 'Tota' },
      { value: 15.835, label: 'Turmequé' },
      { value: 15.839, label: 'Tutazá' },
      { value: 15.842, label: 'Umbita' },
      { value: 15.861, label: 'Ventaquemada' },
      { value: 15.879, label: 'Viracachá' },
      { value: 15.897, label: 'Zetaquira' },
      { value: 15.816, label: 'Togüí' },
      { value: 15.407, label: 'Villa de Leyva' },
      { value: 15.537, label: 'Paz de Río' },
      { value: 15.693, label: 'Santa Rosa de Viterbo' },
      { value: 15.681, label: 'San Pablo de Borbur' },
      { value: 15.667, label: 'San Luis de Gaceno' },
      { value: 15.664, label: 'San José de Pare' },
      { value: 15.676, label: 'San Miguel de Sema' },
      { value: 15.837, label: 'Tuta' },
      { value: 15.806, label: 'Tibasosa' },
      { value: 15.403, label: 'La Uvita' },
      { value: 15.087, label: 'Belén' },
    ]
  },
  {
    value: 17,
    label: 'Caldas',
    ciudades: [
      { value: 17.001, label: 'Manizales' },
      { value: 17.013, label: 'Aguadas' },
      { value: 17.042, label: 'Anserma' },
      { value: 17.05, label: 'Aranzazu' },
      { value: 17.088, label: 'Belalcázar' },
      { value: 17.174, label: 'Chinchiná' },
      { value: 17.272, label: 'Filadelfia' },
      { value: 17.38, label: 'La Dorada' },
      { value: 17.388, label: 'La Merced' },
      { value: 17.433, label: 'Manzanares' },
      { value: 17.442, label: 'Marmato' },
      { value: 17.446, label: 'Marulanda' },
      { value: 17.486, label: 'Neira' },
      { value: 17.495, label: 'Norcasia' },
      { value: 17.513, label: 'Pácora' },
      { value: 17.524, label: 'Palestina' },
      { value: 17.541, label: 'Pensilvania' },
      { value: 17.614, label: 'Riosucio' },
      { value: 17.616, label: 'Risaralda' },
      { value: 17.653, label: 'Salamina' },
      { value: 17.662, label: 'Samaná' },
      { value: 17.665, label: 'San José' },
      { value: 17.777, label: 'Supía' },
      { value: 17.867, label: 'Victoria' },
      { value: 17.873, label: 'Villamaría' },
      { value: 17.877, label: 'Viterbo' },
      { value: 17.444, label: 'Marquetalia' },
    ]
  },
  {
    value: 18,
    label: 'Caquetá',
    ciudades: [
      { value: 18.001, label: 'Florencia' },
      { value: 18.029, label: 'Albania' },
      { value: 18.205, label: 'Curillo' },
      { value: 18.247, label: 'El Doncello' },
      { value: 18.256, label: 'El Paujil' },
      { value: 18.479, label: 'Morelia' },
      { value: 18.592, label: 'Puerto Rico' },
      { value: 18.756, label: 'Solano' },
      { value: 18.785, label: 'Solita' },
      { value: 18.86, label: 'Valparaíso' },
      { value: 18.61, label: 'San José del Fragua' },
      { value: 18.094, label: 'Belén de Los Andaquies' },
      { value: 18.15, label: 'Cartagena del Chairá' },
      { value: 18.46, label: 'Milán' },
      { value: 18.41, label: 'La Montañita' },
      { value: 18.753, label: 'San Vicente del Caguán' },
    ]
  },
  {
    value: 85,
    label: 'Casanare',
    ciudades: [
      { value: 85.001, label: 'Yopal' },
      { value: 85.01, label: 'Aguazul' },
      { value: 85.015, label: 'Chámeza' },
      { value: 85.125, label: 'Hato Corozal' },
      { value: 85.136, label: 'La Salina' },
      { value: 85.162, label: 'Monterrey' },
      { value: 85.263, label: 'Pore' },
      { value: 85.279, label: 'Recetor' },
      { value: 85.3, label: 'Sabanalarga' },
      { value: 85.315, label: 'Sácama' },
      { value: 85.41, label: 'Tauramena' },
      { value: 85.43, label: 'Trinidad' },
      { value: 85.44, label: 'Villanueva' },
      { value: 85.325, label: 'San Luis de Gaceno' },
      { value: 85.25, label: 'Paz de Ariporo' },
      { value: 85.225, label: 'Nunchía' },
      { value: 85.139, label: 'Maní' },
      { value: 85.4, label: 'Támara' },
      { value: 85.23, label: 'Orocué' },
    ]
  },
  {
    value: 19,
    label: 'Cauca',
    ciudades: [
      { value: 19.001, label: 'Popayán' },
      { value: 19.022, label: 'Almaguer' },
      { value: 19.05, label: 'Argelia' },
      { value: 19.075, label: 'Balboa' },
      { value: 19.1, label: 'Bolívar' },
      { value: 19.11, label: 'Buenos Aires' },
      { value: 19.13, label: 'Cajibío' },
      { value: 19.137, label: 'Caldono' },
      { value: 19.142, label: 'Caloto' },
      { value: 19.212, label: 'Corinto' },
      { value: 19.256, label: 'El Tambo' },
      { value: 19.29, label: 'Florencia' },
      { value: 19.3, label: 'Guachené' },
      { value: 19.318, label: 'Guapi' },
      { value: 19.355, label: 'Inzá' },
      { value: 19.364, label: 'Jambaló' },
      { value: 19.392, label: 'La Sierra' },
      { value: 19.397, label: 'La Vega' },
      { value: 19.418, label: 'López' },
      { value: 19.45, label: 'Mercaderes' },
      { value: 19.455, label: 'Miranda' },
      { value: 19.473, label: 'Morales' },
      { value: 19.513, label: 'Padilla' },
      { value: 19.532, label: 'Patía' },
      { value: 19.533, label: 'Piamonte' },
      { value: 19.548, label: 'Piendamó' },
      { value: 19.573, label: 'Puerto Tejada' },
      { value: 19.585, label: 'Puracé' },
      { value: 19.622, label: 'Rosas' },
      { value: 19.701, label: 'Santa Rosa' },
      { value: 19.743, label: 'Silvia' },
      { value: 19.76, label: 'Sotara' },
      { value: 19.78, label: 'Suárez' },
      { value: 19.785, label: 'Sucre' },
      { value: 19.807, label: 'Timbío' },
      { value: 19.809, label: 'Timbiquí' },
      { value: 19.821, label: 'Toribio' },
      { value: 19.824, label: 'Totoró' },
      { value: 19.845, label: 'Villa Rica' },
      { value: 19.698, label: 'Santander de Quilichao' },
      { value: 19.693, label: 'San Sebastián' },
      { value: 19.517, label: 'Páez' },
    ]
  },
  {
    value: 20,
    label: 'Cesar',
    ciudades: [
      { value: 20.001, label: 'Valledupar' },
      { value: 20.011, label: 'Aguachica' },
      { value: 20.013, label: 'Agustín Codazzi' },
      { value: 20.032, label: 'Astrea' },
      { value: 20.045, label: 'Becerril' },
      { value: 20.06, label: 'Bosconia' },
      { value: 20.175, label: 'Chimichagua' },
      { value: 20.178, label: 'Chiriguaná' },
      { value: 20.228, label: 'Curumaní' },
      { value: 20.238, label: 'El Copey' },
      { value: 20.25, label: 'El Paso' },
      { value: 20.295, label: 'Gamarra' },
      { value: 20.31, label: 'González' },
      { value: 20.383, label: 'La Gloria' },
      { value: 20.443, label: 'Manaure' },
      { value: 20.517, label: 'Pailitas' },
      { value: 20.55, label: 'Pelaya' },
      { value: 20.57, label: 'Pueblo Bello' },
      { value: 20.621, label: 'La Paz' },
      { value: 20.71, label: 'San Alberto' },
      { value: 20.75, label: 'San Diego' },
      { value: 20.77, label: 'San Martín' },
      { value: 20.787, label: 'Tamalameque' },
      { value: 20.614, label: 'Río de Oro' },
      { value: 20.4, label: 'La Jagua de Ibirico' },
    ]
  },
  {
    value: 27,
    label: 'Chocó',
    ciudades: [
      { value: 27.361, label: 'Istmina' },
      { value: 27.001, label: 'Quibdó' },
      { value: 27.006, label: 'Acandí' },
      { value: 27.025, label: 'Alto Baudo' },
      { value: 27.05, label: 'Atrato' },
      { value: 27.073, label: 'Bagadó' },
      { value: 27.075, label: 'Bahía Solano' },
      { value: 27.077, label: 'Bajo Baudó' },
      { value: 27.099, label: 'Bojaya' },
      { value: 27.16, label: 'Cértegui' },
      { value: 27.205, label: 'Condoto' },
      { value: 27.372, label: 'Juradó' },
      { value: 27.413, label: 'Lloró' },
      { value: 27.425, label: 'Medio Atrato' },
      { value: 27.43, label: 'Medio Baudó' },
      { value: 27.45, label: 'Medio San Juan' },
      { value: 27.491, label: 'Nóvita' },
      { value: 27.495, label: 'Nuquí' },
      { value: 27.58, label: 'Río Iro' },
      { value: 27.6, label: 'Río Quito' },
      { value: 27.615, label: 'Riosucio' },
      { value: 27.745, label: 'Sipí' },
      { value: 27.8, label: 'Unguía' },
      { value: 27.25, label: 'El Litoral del San Juan' },
      { value: 27.135, label: 'El Cantón del San Pablo' },
      { value: 27.245, label: 'El Carmen de Atrato' },
      { value: 27.66, label: 'San José del Palmar' },
      { value: 27.086, label: 'Belén de Bajira' },
      { value: 27.15, label: 'Carmen del Darien' },
      { value: 27.787, label: 'Tadó' },
      { value: 27.81, label: 'Unión Panamericana' },
    ]
  },
  {
    value: 25,
    label: 'Cundinamarca',
    ciudades: [
      { value: 25.035, label: 'Anapoima' },
      { value: 25.053, label: 'Arbeláez' },
      { value: 25.086, label: 'Beltrán' },
      { value: 25.095, label: 'Bituima' },
      { value: 25.099, label: 'Bojacá' },
      { value: 25.12, label: 'Cabrera' },
      { value: 25.123, label: 'Cachipay' },
      { value: 25.126, label: 'Cajicá' },
      { value: 25.148, label: 'Caparrapí' },
      { value: 25.151, label: 'Caqueza' },
      { value: 25.168, label: 'Chaguaní' },
      { value: 25.178, label: 'Chipaque' },
      { value: 25.181, label: 'Choachí' },
      { value: 25.183, label: 'Chocontá' },
      { value: 25.2, label: 'Cogua' },
      { value: 25.214, label: 'Cota' },
      { value: 25.224, label: 'Cucunubá' },
      { value: 25.245, label: 'El Colegio' },
      { value: 25.26, label: 'El Rosal' },
      { value: 25.279, label: 'Fomeque' },
      { value: 25.281, label: 'Fosca' },
      { value: 25.286, label: 'Funza' },
      { value: 25.288, label: 'Fúquene' },
      { value: 25.293, label: 'Gachala' },
      { value: 25.295, label: 'Gachancipá' },
      { value: 25.297, label: 'Gachetá' },
      { value: 25.307, label: 'Girardot' },
      { value: 25.312, label: 'Granada' },
      { value: 25.317, label: 'Guachetá' },
      { value: 25.32, label: 'Guaduas' },
      { value: 25.322, label: 'Guasca' },
      { value: 25.324, label: 'Guataquí' },
      { value: 25.326, label: 'Guatavita' },
      { value: 25.335, label: 'Guayabetal' },
      { value: 25.339, label: 'Gutiérrez' },
      { value: 25.368, label: 'Jerusalén' },
      { value: 25.372, label: 'Junín' },
      { value: 25.377, label: 'La Calera' },
      { value: 25.386, label: 'La Mesa' },
      { value: 25.394, label: 'La Palma' },
      { value: 25.398, label: 'La Peña' },
      { value: 25.402, label: 'La Vega' },
      { value: 25.407, label: 'Lenguazaque' },
      { value: 25.426, label: 'Macheta' },
      { value: 25.43, label: 'Madrid' },
      { value: 25.436, label: 'Manta' },
      { value: 25.438, label: 'Medina' },
      { value: 25.473, label: 'Mosquera' },
      { value: 25.483, label: 'Nariño' },
      { value: 25.486, label: 'Nemocón' },
      { value: 25.488, label: 'Nilo' },
      { value: 25.489, label: 'Nimaima' },
      { value: 25.491, label: 'Nocaima' },
      { value: 25.506, label: 'Venecia' },
      { value: 25.513, label: 'Pacho' },
      { value: 25.518, label: 'Paime' },
      { value: 25.524, label: 'Pandi' },
      { value: 25.53, label: 'Paratebueno' },
      { value: 25.535, label: 'Pasca' },
      { value: 25.572, label: 'Puerto Salgar' },
      { value: 25.58, label: 'Pulí' },
      { value: 25.592, label: 'Quebradanegra' },
      { value: 25.594, label: 'Quetame' },
      { value: 25.596, label: 'Quipile' },
      { value: 25.599, label: 'Apulo' },
      { value: 25.612, label: 'Ricaurte' },
      { value: 25.649, label: 'San Bernardo' },
      { value: 25.653, label: 'San Cayetano' },
      { value: 25.658, label: 'San Francisco' },
      { value: 25.736, label: 'Sesquilé' },
      { value: 25.74, label: 'Sibaté' },
      { value: 25.743, label: 'Silvania' },
      { value: 25.745, label: 'Simijaca' },
      { value: 25.754, label: 'Soacha' },
      { value: 25.769, label: 'Subachoque' },
      { value: 25.772, label: 'Suesca' },
      { value: 25.777, label: 'Supatá' },
      { value: 25.779, label: 'Susa' },
      { value: 25.781, label: 'Sutatausa' },
      { value: 25.785, label: 'Tabio' },
      { value: 25.793, label: 'Tausa' },
      { value: 25.797, label: 'Tena' },
      { value: 25.799, label: 'Tenjo' },
      { value: 25.805, label: 'Tibacuy' },
      { value: 25.807, label: 'Tibirita' },
      { value: 25.815, label: 'Tocaima' },
      { value: 25.817, label: 'Tocancipá' },
      { value: 25.823, label: 'Topaipí' },
      { value: 25.839, label: 'Ubalá' },
      { value: 25.841, label: 'Ubaque' },
      { value: 25.845, label: 'Une' },
      { value: 25.851, label: 'Útica' },
      { value: 25.867, label: 'Vianí' },
      { value: 25.871, label: 'Villagómez' },
      { value: 25.873, label: 'Villapinzón' },
      { value: 25.875, label: 'Villeta' },
      { value: 25.878, label: 'Viotá' },
      { value: 25.898, label: 'Zipacón' },
      { value: 25.662, label: 'San Juan de Río Seco' },
      { value: 25.843, label: 'Villa de San Diego de Ubate' },
      { value: 25.328, label: 'Guayabal de Siquima' },
      { value: 25.645, label: 'San Antonio del Tequendama' },
      { value: 25.001, label: 'Agua de Dios' },
      { value: 25.154, label: 'Carmen de Carupa' },
      { value: 25.862, label: 'Vergara' },
      { value: 25.019, label: 'Albán' },
      { value: 25.04, label: 'Anolaima' },
      { value: 25.175, label: 'Chía' },
      { value: 25.258, label: 'El Peñón' },
      { value: 25.758, label: 'Sopó' },
      { value: 25.299, label: 'Gama' },
      { value: 25.718, label: 'Sasaima' },
      { value: 25.885, label: 'Yacopí' },
      { value: 25.29, label: 'Fusagasugá' },
      { value: 25.899, label: 'Zipaquirá' },
      { value: 25.269, label: 'Facatativá' },
    ]
  },
  {
    value: 23,
    label: 'Córdoba',
    ciudades: [
      { value: 23.675, label: 'San Bernardo del Viento' },
      { value: 23.001, label: 'Montería' },
      { value: 23.068, label: 'Ayapel' },
      { value: 23.079, label: 'Buenavista' },
      { value: 23.09, label: 'Canalete' },
      { value: 23.162, label: 'Cereté' },
      { value: 23.168, label: 'Chimá' },
      { value: 23.182, label: 'Chinú' },
      { value: 23.3, label: 'Cotorra' },
      { value: 23.417, label: 'Lorica' },
      { value: 23.419, label: 'Los Córdobas' },
      { value: 23.464, label: 'Momil' },
      { value: 23.5, label: 'Moñitos' },
      { value: 23.555, label: 'Planeta Rica' },
      { value: 23.57, label: 'Pueblo Nuevo' },
      { value: 23.574, label: 'Puerto Escondido' },
      { value: 23.586, label: 'Purísima' },
      { value: 23.66, label: 'Sahagún' },
      { value: 23.67, label: 'San Andrés Sotavento' },
      { value: 23.672, label: 'San Antero' },
      { value: 23.686, label: 'San Pelayo' },
      { value: 23.807, label: 'Tierralta' },
      { value: 23.815, label: 'Tuchín' },
      { value: 23.855, label: 'Valencia' },
      { value: 23.682, label: 'San José de Uré' },
      { value: 23.189, label: 'Ciénaga de Oro' },
      { value: 23.678, label: 'San Carlos' },
      { value: 23.466, label: 'Montelíbano' },
      { value: 23.35, label: 'La Apartada' },
      { value: 23.58, label: 'Puerto Libertador' },
    ]
  },
  {
    value: 94,
    label: 'Guainía',
    ciudades: [
      { value: 94.001, label: 'Inírida' },
      { value: 94.343, label: 'Barranco Minas' },
      { value: 94.663, label: 'Mapiripana' },
      { value: 94.883, label: 'San Felipe' },
      { value: 94.884, label: 'Puerto Colombia' },
      { value: 94.885, label: 'La Guadalupe' },
      { value: 94.886, label: 'Cacahual' },
      { value: 94.887, label: 'Pana Pana' },
      { value: 94.888, label: 'Morichal' },
    ]
  },
  {
    value: 95,
    label: 'Guaviare',
    ciudades: [
      { value: 95.015, label: 'Calamar' },
      { value: 95.001, label: 'San José del Guaviare' },
      { value: 95.2, label: 'Miraflores' },
      { value: 95.025, label: 'El Retorno' },
    ]
  },
  {
    value: 41,
    label: 'Huila',
    ciudades: [
      { value: 41.001, label: 'Neiva' },
      { value: 41.006, label: 'Acevedo' },
      { value: 41.013, label: 'Agrado' },
      { value: 41.016, label: 'Aipe' },
      { value: 41.02, label: 'Algeciras' },
      { value: 41.026, label: 'Altamira' },
      { value: 41.078, label: 'Baraya' },
      { value: 41.132, label: 'Campoalegre' },
      { value: 41.206, label: 'Colombia' },
      { value: 41.244, label: 'Elías' },
      { value: 41.298, label: 'Garzón' },
      { value: 41.306, label: 'Gigante' },
      { value: 41.319, label: 'Guadalupe' },
      { value: 41.349, label: 'Hobo' },
      { value: 41.357, label: 'Iquira' },
      { value: 41.359, label: 'Isnos' },
      { value: 41.378, label: 'La Argentina' },
      { value: 41.396, label: 'La Plata' },
      { value: 41.483, label: 'Nátaga' },
      { value: 41.503, label: 'Oporapa' },
      { value: 41.518, label: 'Paicol' },
      { value: 41.524, label: 'Palermo' },
      { value: 41.53, label: 'Palestina' },
      { value: 41.548, label: 'Pital' },
      { value: 41.551, label: 'Pitalito' },
      { value: 41.615, label: 'Rivera' },
      { value: 41.66, label: 'Saladoblanco' },
      { value: 41.676, label: 'Santa María' },
      { value: 41.77, label: 'Suaza' },
      { value: 41.791, label: 'Tarqui' },
      { value: 41.797, label: 'Tesalia' },
      { value: 41.799, label: 'Tello' },
      { value: 41.801, label: 'Teruel' },
      { value: 41.807, label: 'Timaná' },
      { value: 41.872, label: 'Villavieja' },
      { value: 41.885, label: 'Yaguará' },
      { value: 41.668, label: 'San Agustín' },
    ]
  },
  {
    value: 44,
    label: 'La Guajira',
    ciudades: [
      { value: 44.001, label: 'Riohacha' },
      { value: 44.035, label: 'Albania' },
      { value: 44.078, label: 'Barrancas' },
      { value: 44.09, label: 'Dibula' },
      { value: 44.098, label: 'Distracción' },
      { value: 44.11, label: 'El Molino' },
      { value: 44.279, label: 'Fonseca' },
      { value: 44.378, label: 'Hatonuevo' },
      { value: 44.43, label: 'Maicao' },
      { value: 44.56, label: 'Manaure' },
      { value: 44.847, label: 'Uribia' },
      { value: 44.855, label: 'Urumita' },
      { value: 44.874, label: 'Villanueva' },
      { value: 44.42, label: 'La Jagua del Pilar' },
      { value: 44.65, label: 'San Juan del Cesar' },
    ]
  },
  {
    value: 47,
    label: 'Magdalena',
    ciudades: [
      { value: 47.001, label: 'Santa Marta' },
      { value: 47.03, label: 'Algarrobo' },
      { value: 47.053, label: 'Aracataca' },
      { value: 47.058, label: 'Ariguaní' },
      { value: 47.161, label: 'Cerro San Antonio' },
      { value: 47.17, label: 'Chivolo' },
      { value: 47.205, label: 'Concordia' },
      { value: 47.245, label: 'El Banco' },
      { value: 47.258, label: 'El Piñon' },
      { value: 47.268, label: 'El Retén' },
      { value: 47.288, label: 'Fundación' },
      { value: 47.318, label: 'Guamal' },
      { value: 47.46, label: 'Nueva Granada' },
      { value: 47.541, label: 'Pedraza' },
      { value: 47.551, label: 'Pivijay' },
      { value: 47.555, label: 'Plato' },
      { value: 47.605, label: 'Remolino' },
      { value: 47.675, label: 'Salamina' },
      { value: 47.703, label: 'San Zenón' },
      { value: 47.707, label: 'Santa Ana' },
      { value: 47.745, label: 'Sitionuevo' },
      { value: 47.798, label: 'Tenerife' },
      { value: 47.96, label: 'Zapayán' },
      { value: 47.98, label: 'Zona Bananera' },
      { value: 47.692, label: 'San Sebastián de Buenavista' },
      { value: 47.66, label: 'Sabanas de San Angel' },
      { value: 47.545, label: 'Pijiño del Carmen' },
      { value: 47.72, label: 'Santa Bárbara de Pinto' },
      { value: 47.57, label: 'Pueblo Viejo' },
      { value: 47.189, label: 'Ciénaga' },
    ]
  },
  {
    value: 50,
    label: 'Meta',
    ciudades: [
      { value: 50.37, label: 'Uribe' },
      { value: 50.001, label: 'Villavicencio' },
      { value: 50.006, label: 'Acacias' },
      { value: 50.124, label: 'Cabuyaro' },
      { value: 50.223, label: 'Cubarral' },
      { value: 50.226, label: 'Cumaral' },
      { value: 50.245, label: 'El Calvario' },
      { value: 50.251, label: 'El Castillo' },
      { value: 50.27, label: 'El Dorado' },
      { value: 50.313, label: 'Granada' },
      { value: 50.318, label: 'Guamal' },
      { value: 50.325, label: 'Mapiripán' },
      { value: 50.33, label: 'Mesetas' },
      { value: 50.35, label: 'La Macarena' },
      { value: 50.4, label: 'Lejanías' },
      { value: 50.45, label: 'Puerto Concordia' },
      { value: 50.568, label: 'Puerto Gaitán' },
      { value: 50.573, label: 'Puerto López' },
      { value: 50.577, label: 'Puerto Lleras' },
      { value: 50.59, label: 'Puerto Rico' },
      { value: 50.606, label: 'Restrepo' },
      { value: 50.686, label: 'San Juanito' },
      { value: 50.689, label: 'San Martín' },
      { value: 50.711, label: 'Vista Hermosa' },
      { value: 50.11, label: 'Barranca de Upía' },
      { value: 50.287, label: 'Fuente de Oro' },
      { value: 50.68, label: 'San Carlos de Guaroa' },
      { value: 50.683, label: 'San Juan de Arama' },
      { value: 50.15, label: 'Castilla la Nueva' },
    ]
  },
  {
    value: 52,
    label: 'Nariño',
    ciudades: [
      { value: 52.699, label: 'Santacruz' },
      { value: 52.001, label: 'Pasto' },
      { value: 52.019, label: 'Albán' },
      { value: 52.022, label: 'Aldana' },
      { value: 52.036, label: 'Ancuyá' },
      { value: 52.079, label: 'Barbacoas' },
      { value: 52.203, label: 'Colón' },
      { value: 52.207, label: 'Consaca' },
      { value: 52.21, label: 'Contadero' },
      { value: 52.215, label: 'Córdoba' },
      { value: 52.224, label: 'Cuaspud' },
      { value: 52.227, label: 'Cumbal' },
      { value: 52.233, label: 'Cumbitara' },
      { value: 52.25, label: 'El Charco' },
      { value: 52.254, label: 'El Peñol' },
      { value: 52.256, label: 'El Rosario' },
      { value: 52.26, label: 'El Tambo' },
      { value: 52.287, label: 'Funes' },
      { value: 52.317, label: 'Guachucal' },
      { value: 52.32, label: 'Guaitarilla' },
      { value: 52.323, label: 'Gualmatán' },
      { value: 52.352, label: 'Iles' },
      { value: 52.354, label: 'Imués' },
      { value: 52.356, label: 'Ipiales' },
      { value: 52.378, label: 'La Cruz' },
      { value: 52.381, label: 'La Florida' },
      { value: 52.385, label: 'La Llanada' },
      { value: 52.39, label: 'La Tola' },
      { value: 52.399, label: 'La Unión' },
      { value: 52.405, label: 'Leiva' },
      { value: 52.411, label: 'Linares' },
      { value: 52.418, label: 'Los Andes' },
      { value: 52.427, label: 'Magüí' },
      { value: 52.435, label: 'Mallama' },
      { value: 52.473, label: 'Mosquera' },
      { value: 52.48, label: 'Nariño' },
      { value: 52.49, label: 'Olaya Herrera' },
      { value: 52.506, label: 'Ospina' },
      { value: 52.52, label: 'Francisco Pizarro' },
      { value: 52.54, label: 'Policarpa' },
      { value: 52.56, label: 'Potosí' },
      { value: 52.565, label: 'Providencia' },
      { value: 52.573, label: 'Puerres' },
      { value: 52.585, label: 'Pupiales' },
      { value: 52.612, label: 'Ricaurte' },
      { value: 52.621, label: 'Roberto Payán' },
      { value: 52.678, label: 'Samaniego' },
      { value: 52.683, label: 'Sandoná' },
      { value: 52.685, label: 'San Bernardo' },
      { value: 52.687, label: 'San Lorenzo' },
      { value: 52.693, label: 'San Pablo' },
      { value: 52.696, label: 'Santa Bárbara' },
      { value: 52.72, label: 'Sapuyes' },
      { value: 52.786, label: 'Taminango' },
      { value: 52.788, label: 'Tangua' },
      { value: 52.838, label: 'Túquerres' },
      { value: 52.885, label: 'Yacuanquer' },
      { value: 52.694, label: 'San Pedro de Cartago' },
      { value: 52.258, label: 'El Tablón de Gómez' },
      { value: 52.11, label: 'Buesaco' },
      { value: 52.835, label: 'San Andrés de Tumaco' },
      { value: 52.083, label: 'Belén' },
      { value: 52.24, label: 'Chachagüí' },
      { value: 52.051, label: 'Arboleda' },
    ]
  },
  {
    value: 54,
    label: 'Norte de Santander',
    ciudades: [
      { value: 54.743, label: 'Silos' },
      { value: 54.125, label: 'Cácota' },
      { value: 54.82, label: 'Toledo' },
      { value: 54.48, label: 'Mutiscua' },
      { value: 54.261, label: 'El Zulia' },
      { value: 54.66, label: 'Salazar' },
      { value: 54.223, label: 'Cucutilla' },
      { value: 54.553, label: 'Puerto Santander' },
      { value: 54.313, label: 'Gramalote' },
      { value: 54.25, label: 'El Tarra' },
      { value: 54.8, label: 'Teorama' },
      { value: 54.051, label: 'Arboledas' },
      { value: 54.418, label: 'Lourdes' },
      { value: 54.099, label: 'Bochalema' },
      { value: 54.206, label: 'Convención' },
      { value: 54.344, label: 'Hacarí' },
      { value: 54.347, label: 'Herrán' },
      { value: 54.81, label: 'Tibú' },
      { value: 54.673, label: 'San Cayetano' },
      { value: 54.67, label: 'San Calixto' },
      { value: 54.398, label: 'La Playa' },
      { value: 54.172, label: 'Chinácota' },
      { value: 54.599, label: 'Ragonvalia' },
      { value: 54.385, label: 'La Esperanza' },
      { value: 54.874, label: 'Villa del Rosario' },
      { value: 54.174, label: 'Chitagá' },
      { value: 54.72, label: 'Sardinata' },
      { value: 54.003, label: 'Abrego' },
      { value: 54.405, label: 'Los Patios' },
      { value: 54.498, label: 'Ocaña' },
      { value: 54.109, label: 'Bucarasica' },
      { value: 54.68, label: 'Santiago' },
      { value: 54.377, label: 'Labateca' },
      { value: 54.128, label: 'Cachirá' },
      { value: 54.871, label: 'Villa Caro' },
      { value: 54.239, label: 'Durania' },
      { value: 54.518, label: 'Pamplona' },
      { value: 54.52, label: 'Pamplonita' },
      { value: 54.001, label: 'Cúcuta' },
      { value: 54.245, label: 'El Carmen' },
    ]
  },
  {
    value: 86,
    label: 'Putumayo',
    ciudades: [
      { value: 86.001, label: 'Mocoa' },
      { value: 86.219, label: 'Colón' },
      { value: 86.32, label: 'Orito' },
      { value: 86.569, label: 'Puerto Caicedo' },
      { value: 86.571, label: 'Puerto Guzmán' },
      { value: 86.573, label: 'Leguízamo' },
      { value: 86.749, label: 'Sibundoy' },
      { value: 86.755, label: 'San Francisco' },
      { value: 86.757, label: 'San Miguel' },
      { value: 86.76, label: 'Santiago' },
      { value: 86.865, label: 'Valle de Guamez' },
      { value: 86.568, label: 'Puerto Asís' },
      { value: 86.885, label: 'Villagarzón' },
    ]
  },
  {
    value: 63,
    label: 'Quindío',
    ciudades: [
      { value: 63.001, label: 'Armenia' },
      { value: 63.111, label: 'Buenavista' },
      { value: 63.19, label: 'Circasia' },
      { value: 63.212, label: 'Córdoba' },
      { value: 63.272, label: 'Filandia' },
      { value: 63.401, label: 'La Tebaida' },
      { value: 63.47, label: 'Montenegro' },
      { value: 63.548, label: 'Pijao' },
      { value: 63.594, label: 'Quimbaya' },
      { value: 63.69, label: 'Salento' },
      { value: 63.13, label: 'Calarcá' },
      { value: 63.302, label: 'Génova' },
    ]
  },
  {
    value: 66,
    label: 'Risaralda',
    ciudades: [
      { value: 66.001, label: 'Pereira' },
      { value: 66.045, label: 'Apía' },
      { value: 66.075, label: 'Balboa' },
      { value: 66.17, label: 'Dosquebradas' },
      { value: 66.318, label: 'Guática' },
      { value: 66.383, label: 'La Celia' },
      { value: 66.4, label: 'La Virginia' },
      { value: 66.44, label: 'Marsella' },
      { value: 66.456, label: 'Mistrató' },
      { value: 66.572, label: 'Pueblo Rico' },
      { value: 66.594, label: 'Quinchía' },
      { value: 66.687, label: 'Santuario' },
      { value: 66.682, label: 'Santa Rosa de Cabal' },
      { value: 66.088, label: 'Belén de Umbría' },
    ]
  },
  {
    value: 68,
    label: 'Santander',
    ciudades: [
      { value: 68.575, label: 'Puerto Wilches' },
      { value: 68.573, label: 'Puerto Parra' },
      { value: 68.001, label: 'Bucaramanga' },
      { value: 68.013, label: 'Aguada' },
      { value: 68.02, label: 'Albania' },
      { value: 68.051, label: 'Aratoca' },
      { value: 68.077, label: 'Barbosa' },
      { value: 68.079, label: 'Barichara' },
      { value: 68.081, label: 'Barrancabermeja' },
      { value: 68.092, label: 'Betulia' },
      { value: 68.101, label: 'Bolívar' },
      { value: 68.121, label: 'Cabrera' },
      { value: 68.132, label: 'California' },
      { value: 68.152, label: 'Carcasí' },
      { value: 68.16, label: 'Cepitá' },
      { value: 68.162, label: 'Cerrito' },
      { value: 68.167, label: 'Charalá' },
      { value: 68.169, label: 'Charta' },
      { value: 68.179, label: 'Chipatá' },
      { value: 68.19, label: 'Cimitarra' },
      { value: 68.207, label: 'Concepción' },
      { value: 68.209, label: 'Confines' },
      { value: 68.211, label: 'Contratación' },
      { value: 68.217, label: 'Coromoro' },
      { value: 68.229, label: 'Curití' },
      { value: 68.245, label: 'El Guacamayo' },
      { value: 68.255, label: 'El Playón' },
      { value: 68.264, label: 'Encino' },
      { value: 68.266, label: 'Enciso' },
      { value: 68.271, label: 'Florián' },
      { value: 68.276, label: 'Floridablanca' },
      { value: 68.296, label: 'Galán' },
      { value: 68.298, label: 'Gambita' },
      { value: 68.307, label: 'Girón' },
      { value: 68.318, label: 'Guaca' },
      { value: 68.32, label: 'Guadalupe' },
      { value: 68.322, label: 'Guapotá' },
      { value: 68.324, label: 'Guavatá' },
      { value: 68.327, label: 'Güepsa' },
      { value: 68.368, label: 'Jesús María' },
      { value: 68.37, label: 'Jordán' },
      { value: 68.377, label: 'La Belleza' },
      { value: 68.385, label: 'Landázuri' },
      { value: 68.397, label: 'La Paz' },
      { value: 68.406, label: 'Lebríja' },
      { value: 68.418, label: 'Los Santos' },
      { value: 68.425, label: 'Macaravita' },
      { value: 68.432, label: 'Málaga' },
      { value: 68.444, label: 'Matanza' },
      { value: 68.464, label: 'Mogotes' },
      { value: 68.468, label: 'Molagavita' },
      { value: 68.498, label: 'Ocamonte' },
      { value: 68.5, label: 'Oiba' },
      { value: 68.502, label: 'Onzaga' },
      { value: 68.522, label: 'Palmar' },
      { value: 68.533, label: 'Páramo' },
      { value: 68.547, label: 'Piedecuesta' },
      { value: 68.549, label: 'Pinchote' },
      { value: 68.572, label: 'Puente Nacional' },
      { value: 68.615, label: 'Rionegro' },
      { value: 68.669, label: 'San Andrés' },
      { value: 68.679, label: 'San Gil' },
      { value: 68.682, label: 'San Joaquín' },
      { value: 68.686, label: 'San Miguel' },
      { value: 68.705, label: 'Santa Bárbara' },
      { value: 68.745, label: 'Simacota' },
      { value: 68.755, label: 'Socorro' },
      { value: 68.77, label: 'Suaita' },
      { value: 68.773, label: 'Sucre' },
      { value: 68.78, label: 'Suratá' },
      { value: 68.82, label: 'Tona' },
      { value: 68.861, label: 'Vélez' },
      { value: 68.867, label: 'Vetas' },
      { value: 68.872, label: 'Villanueva' },
      { value: 68.895, label: 'Zapatoca' },
      { value: 68.524, label: 'Palmas del Socorro' },
      { value: 68.689, label: 'San Vicente de Chucurí' },
      { value: 68.684, label: 'San José de Miranda' },
      { value: 68.72, label: 'Santa Helena del Opón' },
      { value: 68.655, label: 'Sabana de Torres' },
      { value: 68.235, label: 'El Carmen de Chucurí' },
      { value: 68.855, label: 'Valle de San José' },
      { value: 68.673, label: 'San Benito' },
      { value: 68.344, label: 'Hato' },
      { value: 68.176, label: 'Chimá' },
      { value: 68.147, label: 'Capitanejo' },
      { value: 68.25, label: 'El Peñón' },
      { value: '68.vado', label: 'Vado Real'}
    ]
  },
  {
    value: 70,
    label: 'Sucre',
    ciudades: [
      { value: 70.001, label: 'Sincelejo' },
      { value: 70.11, label: 'Buenavista' },
      { value: 70.124, label: 'Caimito' },
      { value: 70.204, label: 'Coloso' },
      { value: 70.221, label: 'Coveñas' },
      { value: 70.23, label: 'Chalán' },
      { value: 70.233, label: 'El Roble' },
      { value: 70.235, label: 'Galeras' },
      { value: 70.265, label: 'Guaranda' },
      { value: 70.4, label: 'La Unión' },
      { value: 70.418, label: 'Los Palmitos' },
      { value: 70.429, label: 'Majagual' },
      { value: 70.473, label: 'Morroa' },
      { value: 70.508, label: 'Ovejas' },
      { value: 70.523, label: 'Palmito' },
      { value: 70.678, label: 'San Benito Abad' },
      { value: 70.708, label: 'San Marcos' },
      { value: 70.713, label: 'San Onofre' },
      { value: 70.717, label: 'San Pedro' },
      { value: 70.771, label: 'Sucre' },
      { value: 70.823, label: 'Tolú Viejo' },
      { value: 70.742, label: 'San Luis de Sincé' },
      { value: 70.702, label: 'San Juan de Betulia' },
      { value: 70.82, label: 'Santiago de Tolú' },
      { value: 70.67, label: 'Sampués' },
      { value: 70.215, label: 'Corozal' },
    ]
  },
  {
    value: 73,
    label: 'Tolima',
    ciudades: [
      { value: 73.024, label: 'Alpujarra' },
      { value: 73.026, label: 'Alvarado' },
      { value: 73.03, label: 'Ambalema' },
      { value: 73.055, label: 'Armero' },
      { value: 73.067, label: 'Ataco' },
      { value: 73.124, label: 'Cajamarca' },
      { value: 73.168, label: 'Chaparral' },
      { value: 73.2, label: 'Coello' },
      { value: 73.217, label: 'Coyaima' },
      { value: 73.226, label: 'Cunday' },
      { value: 73.236, label: 'Dolores' },
      { value: 73.268, label: 'Espinal' },
      { value: 73.27, label: 'Falan' },
      { value: 73.275, label: 'Flandes' },
      { value: 73.283, label: 'Fresno' },
      { value: 73.319, label: 'Guamo' },
      { value: 73.347, label: 'Herveo' },
      { value: 73.349, label: 'Honda' },
      { value: 73.352, label: 'Icononzo' },
      { value: 73.443, label: 'Mariquita' },
      { value: 73.449, label: 'Melgar' },
      { value: 73.461, label: 'Murillo' },
      { value: 73.483, label: 'Natagaima' },
      { value: 73.504, label: 'Ortega' },
      { value: 73.52, label: 'Palocabildo' },
      { value: 73.547, label: 'Piedras' },
      { value: 73.555, label: 'Planadas' },
      { value: 73.563, label: 'Prado' },
      { value: 73.585, label: 'Purificación' },
      { value: 73.616, label: 'Rio Blanco' },
      { value: 73.622, label: 'Roncesvalles' },
      { value: 73.624, label: 'Rovira' },
      { value: 73.671, label: 'Saldaña' },
      { value: 73.686, label: 'Santa Isabel' },
      { value: 73.861, label: 'Venadillo' },
      { value: 73.87, label: 'Villahermosa' },
      { value: 73.873, label: 'Villarrica' },
      { value: 73.854, label: 'Valle de San Juan' },
      { value: 73.148, label: 'Carmen de Apicala' },
      { value: 73.678, label: 'San Luis' },
      { value: 73.675, label: 'San Antonio' },
      { value: 73.152, label: 'Casabianca' },
      { value: 73.043, label: 'Anzoátegui' },
      { value: 73.001, label: 'Ibagué' },
      { value: 73.411, label: 'Líbano' },
      { value: 73.408, label: 'Lérida' },
      { value: 73.77, label: 'Suárez' },
    ]
  },
  {
    value: 76,
    label: 'Valle del Cauca',
    ciudades: [
      { value: 76.25, label: 'El Dovio' },
      { value: 76.622, label: 'Roldanillo' },
      { value: 76.054, label: 'Argelia' },
      { value: 76.736, label: 'Sevilla' },
      { value: 76.895, label: 'Zarzal' },
      { value: 76.248, label: 'El Cerrito' },
      { value: 76.147, label: 'Cartago' },
      { value: 76.122, label: 'Caicedonia' },
      { value: 76.246, label: 'El Cairo' },
      { value: 76.4, label: 'La Unión' },
      { value: 76.606, label: 'Restrepo' },
      { value: 76.233, label: 'Dagua' },
      { value: 76.318, label: 'Guacarí' },
      { value: 76.041, label: 'Ansermanuevo' },
      { value: 76.113, label: 'Bugalagrande' },
      { value: 76.403, label: 'La Victoria' },
      { value: 76.306, label: 'Ginebra' },
      { value: 76.892, label: 'Yumbo' },
      { value: 76.497, label: 'Obando' },
      { value: 76.1, label: 'Bolívar' },
      { value: 76.001, label: 'Cali' },
      { value: 76.67, label: 'San Pedro' },
      { value: 76.111, label: 'Guadalajara de Buga' },
      { value: 76.126, label: 'Calima' },
      { value: 76.036, label: 'Andalucía' },
      { value: 76.563, label: 'Pradera' },
      { value: 76.89, label: 'Yotoco' },
      { value: 76.52, label: 'Palmira' },
      { value: 76.616, label: 'Riofrío' },
      { value: 76.02, label: 'Alcalá' },
      { value: 76.863, label: 'Versalles' },
      { value: 76.243, label: 'El Águila' },
      { value: 76.823, label: 'Toro' },
      { value: 76.13, label: 'Candelaria' },
      { value: 76.377, label: 'La Cumbre' },
      { value: 76.845, label: 'Ulloa' },
      { value: 76.828, label: 'Trujillo' },
      { value: 76.869, label: 'Vijes' },
      { value: 76.834, label: 'Tuluá' },
      { value: 76.275, label: 'Florida' },
      { value: 76.364, label: 'Jamundí' },
      { value: 76.109, label: 'Buenaventura' },
    ]
  },
  {
    value: 97,
    label: 'Vaupés',
    ciudades: [
      { value: 97.001, label: 'Mitú' },
      { value: 97.161, label: 'Carurú' },
      { value: 97.666, label: 'Taraira' },
      { value: 97.777, label: 'Papunahua' },
      { value: 97.889, label: 'Yavaraté' },
      { value: 97.511, label: 'Pacoa' },
    ]
  },
  {
    value: 99,
    label: 'Vichada',
    ciudades: [
      { value: 99.001, label: 'Puerto Carreño' },
      { value: 99.524, label: 'La Primavera' },
      { value: 99.624, label: 'Santa Rosalía' },
      { value: 99.773, label: 'Cumaribo' },
    ]
  },
];
