import React from 'react';
import './MessageAddress.css';
import Alert from '../../../../images/alerta.png';

const MessageAddress = ({ text, height }) => {
  return (
    <div>
      <div className="messageAddres" style={{ height: height }}>
        <img className='img-alert' src={Alert} alt="" />
        <div className='message-container-adress'>
          {text}
        </div>
      </div>
    </div>
  );
};

export default MessageAddress;
