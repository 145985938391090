import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import DefaultLayout from './layout/DefaultLayout/DefaultLayout';
import RegisterLayout1 from './layout/RegisterLayout1/RegisterLayout1'
import RegisterLayout2 from "./layout/RegisterLayout2/RegisterLayout2";
import './App.css';
import {SectionProvider} from "./context/SectionContext";

function App() {
  return (
      <SectionProvider>
          <Router>
              <Routes>
                  <Route path="/" element={<DefaultLayout />}>
                  </Route>
                  <Route path="/calculadora" element={<DefaultLayout section='calculator'/>}>
                  </Route>
                  <Route path="/crear-casillero" element={<RegisterLayout1 section='register-1'/>}>
                  </Route>
                  <Route path="/crear-casillero-2" element={<RegisterLayout2 section='register-2'/>}>
                  </Route>
                  <Route path="/inicio-sesion" element={<RegisterLayout2 section='login'/>}>
                  </Route>
                  <Route path="/recuperar-contrasena" element={<RegisterLayout2 section='restore-password'/>}>
                  </Route>
                  <Route path="/direcciones" element={<DefaultLayout section='direcciones'/>}>
                  </Route>
                  <Route path="/prealertas" element={<DefaultLayout section='prealertas'/>}>
                  </Route>
                  <Route path="/crear-nueva-contrasena" element={<RegisterLayout1 section='new-password'/>}>
                  </Route>
              </Routes>
          </Router>
      </SectionProvider>
  );
}

export default App;
