import React from 'react';
import LoadingButton from '@mui/lab/LoadingButton';

const LoadingButtonCustom = ({ handleSubmit, loading, isButtonPurple, label, customStyles }) => {
  return (
      <LoadingButton
          className={`botonLoading ${isButtonPurple ? 'boton-morado' : ''}`}
          type="submit"
          variant="contained"
          color="primary"
          loading={loading}
          onClick={handleSubmit}
          sx={{
            '&.MuiButtonBase-root': {
              backgroundColor: "#9e9e9e",
              borderRadius: {xs:"30px", sm:"2vw"},
              width: "100%",
              height: {xs: "10vw", sm: "2.3vw"},
              fontSize: {xs: "4vw", sm: "1.2vw"},
              fontFamily: 'Viga',
              marginTop: "0.6vw",  
              '&:hover': {
                backgroundColor: "#5452e1",
                fontFamily: 'Viga',
              },
              ...customStyles
            }
          }}
      >
        {label}
      </LoadingButton>
  );
};

export default LoadingButtonCustom;
