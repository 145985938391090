import React from 'react';
import './RegisterMessage.css'
import boxregister from '../../../../images/boxregister.png'

const RegisterMessage = () => {
    return (
        <div>
            <div className="register-message">
                <h2 className="register-message-text">Trae lo que<br/> quieras desde
                    <br/>USA a Colombia<br/> super-mega<br/> duper-hiper
                    <br/> rápidoooo y<br/> al mejor precioo</h2>
            </div>
            <img className ="boxregister-img" src={boxregister} alt="Ilustración carrito de compras"></img>
        </div>
    );
};

export default RegisterMessage;
