import React from 'react';
import useRedirectIframe from "../../hooks/useRedirectIframe";

const EmptyPage = () => {
    // Llama al hook al inicio del componente.
    useRedirectIframe('https://wuuju.co/pages/casillero-virtual/');

    // Después de llamar al hook, puedes continuar con el retorno de tu componente JSX.
    return (
        <>
            <div>Selecciona una sección</div>
        </>
    );
};

export default EmptyPage;
