import React, { useEffect, useState } from 'react';
import direcciones from "../../../../images/Slider_Addres.png";
import calculadora2 from "../../../../images/Slider_Calculator.png";
import pagos from "../../../../images/Splider_Payments.png";
import megafono2 from "../../../../images/Slider_Sound.png";
import paquetes from "../../../../images/Slider_Box.png";
import profile from "../../../../images/Slider_Profile.png";
import { useSection } from '../../../../context/SectionContext';
import IconArrow from '../../../../images/ICON_ARROW.png'
import './Sidebar.css'
import { useAuth } from "../../../../hooks/useAuth";

const Sidebar = () => {
    const { activeSection, changeSection } = useSection();
    const [selectedIcon, setSelectedIcon] = useState(null);
    const isAuthenticated = useAuth();

    // Idetifica qué icono resaltar por sección activa
    useEffect(() => {
        switch (activeSection) {
            case 'calculator':
                setSelectedIcon('calculator');
                break;
            case 'FinalPrice':
                setSelectedIcon('calculator');
                break;
            case 'direcciones':
                setSelectedIcon('direcciones');
                break;
            case 'DetallesDirecciones':
                setSelectedIcon('direcciones');
                break;
            case 'prealertas':
                setSelectedIcon('prealertas');
                break;
            default:
                setSelectedIcon(null)
        }
    }, [activeSection]);

    // Logica tratamiendo de css para los items del SideBar
    const SidebarItem = ({ index, image, text }) => {
        // Define las secciones desactivadas
        const [disabledSections, setDisabledSections] = useState(['paquetes', 'profile']);

        useEffect(() => {
            if (!isAuthenticated) {
                setDisabledSections(currentDisabledSections => [...currentDisabledSections, 'direcciones', 'prealertas']);
            }
        }, [isAuthenticated]);

        // Verifica si la sección está desactivada
        const isDisabled = disabledSections.includes(index);

        // Agrega la clase 'disabled' si la sección está desactivada
        const itemClassName = `animation ${selectedIcon === index ? 'active' : ''} ${isDisabled ? 'disabled' : ''}`;

        // Agrega la clase 'grayed-out' si la sección está desactivada
        const imageClassName = `sideimg ${isDisabled ? 'grayed-out' : ''}`;

        // Solo permite cambiar la sección si no está desactivada
        const handleClick = () => {
            if (!isDisabled) {
                changeSection(index);
            }
        };

        return (
            <div
                className={itemClassName}
                onClick={handleClick}
            >
                <img className={imageClassName} src={image} alt="" />
                <span className='sidebar-text'>{text}</span>
            </div>
        );
    };

    // Sistema de movimiento de Sidebar
    const items = [
        { index: 'profile', image: profile, text: 'Perfil' },
        { index: 'direcciones', image: direcciones, text: 'Direcciones' },
        { index: 'prealertas', image: megafono2, text: 'Prealertas' },
        { index: 'paquetes', image: paquetes, text: 'Paquetes' },
        { index: 'profile', image: pagos, text: 'Pagos' },
        { index: 'calculator', image: calculadora2, text: 'Calculadora' },
    ];

    const [currentIndex, setCurrentIndex] = useState(0);

    const handleUpClick = () => {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
        }
    };

    const handleDownClick = () => {
        if (currentIndex < items.length - 5) {
            setCurrentIndex(currentIndex + 1);
        }
    };

    const UpDisabled = currentIndex === 0;
    const DownDisabled = currentIndex >= items.length - 5;

    const visibleItems = items.slice(currentIndex, currentIndex + 5).concat(
        items.slice(0, Math.max(0, 5 - (items.length - currentIndex)))
    );

    return (
        <div>
            <div className="sidebar">
                {/* Fondo del sidebar */}
            </div>
            <div className="sidebar-menu">
                <div className="arrow-container">
                    <img
                        src={IconArrow}
                        alt="Flecha arriba"
                        className={`up-image ${UpDisabled ? 'disabled-arrow' : ''}`}
                        onClick={handleUpClick}
                    />
                </div>

                {visibleItems.map((item, index) => (
                    <SidebarItem key={index} index={item.index} image={item.image} text={item.text} />
                ))}

                <div className="arrow-container">
                    <img
                        src={IconArrow}
                        alt="Flecha abajo"
                        className={`down-image ${DownDisabled ? 'disabled-arrow' : ''}`}
                        onClick={handleDownClick}
                    />
                </div>
            </div>
        </div>
    );
};
export default Sidebar;
