import React from 'react';
import alerta from "../../../../images/alerta.png";
import { useSection } from '../../../../context/SectionContext';
import './Message.css'

const Message = () => {
    const { activeSection } = useSection();

    const getMessageContainerClass = () => {
        if (activeSection === 'FinalPrice') {
            return 'final-price-message-container';
        }
        return '';
    };

    const getAlertImgClass = () => {
        if (activeSection === 'calculator') {
            return 'calculator-alert-img';
        } else if (activeSection === 'FinalPrice') {
            return 'final-price-alert-img';
        }
        return '';
    };

    // Solo renderiza si la sección activa es 'calculator' o 'FinalPrice'
    if (activeSection !== 'calculator' && activeSection !== 'FinalPrice') {
        return null;
    }

    return (
        <div className={`message ${getMessageContainerClass()}`}>
            <div className='messagecontainer'>
                <img className={`alert-img ${getAlertImgClass()}`} src={alerta} alt="" />
                <div className="text-message-container">
                    <span className="text-message">
                    {activeSection === 'calculator' && (
                        <span className="text-message">
                        Recuerda que <span className='text-message2'>El valor comercial NO siempre coincide con el precio que pagas.</span>
                        Déjanos darte un ejemplo: Supongamos que encuentras una increíble
                        <span className='colormessage'> oferta</span> que te permite adquirir un iPhone
                        <span className='colormessage'>15 Pro Max tan solo en 100 USD</span>, sin embargo,
                        este precio no reflejaría su valor real en el mercado; se trataría más bien de una oferta o promoción temporal.
                      </span>
                    )}
                      {activeSection === 'FinalPrice' && (
                          <div className='final-price-container'><span className='final-price-message'>*El valor que se muestra aquí es una estimación basada en los datos que proporcionaste. Ten en cuenta que el resultado final podría variar segun el peso "exacto del produto.</span>
                          </div>
                      )}
                    </span>
                </div>
            </div>
        </div>
    );
};

export default Message;
