import React from 'react';
import './RegisterLayout2.css';
import LayoutBase from "../LayoutBase";
import RegisterMessage2 from "./components/RegisterMessage2/RegisterMessage2";
import RegisterMainContent2 from "./components/RegisterMainContent2/RegisterMainContent2"

function RegisterLayout2({section} ) {
  return (
      <LayoutBase
          sidebarComponent={null}
          mainContent={<RegisterMainContent2 section={section} />}
          additionalComponent={<RegisterMessage2 />}
          additionalClass="register1-layout"
      />
  );
}

export default RegisterLayout2;
