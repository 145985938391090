import React from 'react';
import './LayoutBase.css'
const BaseLayout = ({ sidebarComponent, mainContent, additionalComponent, additionalClass, movilNavigationBar }) => {
    return (
        <div className={`layout ${additionalClass}`}>
            {/*<Header />*/}
            {movilNavigationBar}
            <div className="app-container">
                <div className={`content-container ${additionalClass}`}>
                    {sidebarComponent}
                    {mainContent}
                    {additionalComponent}
                </div>
            </div>
        </div>
    );
};

export default BaseLayout;