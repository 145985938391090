import React from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import MenuItem from "@mui/material/MenuItem";
import {Select} from "@mui/material";

const CustomSelectInput = ({ placeholder, value, startIcon, options, setValue, isValid, onChange, additionalClassName, customStyles, hideValidation }) => {
    const startAdornment = startIcon && (
        <InputAdornment position="start">
            {startIcon}
        </InputAdornment>
    );

    const validationIcon = isValid !== null && (
        <InputAdornment position="end">
            {isValid ? (
                <CheckCircleIcon
                    sx={{
                        color: '#6CE9B1',
                        paddingRight: '1vw'
                    }}
                />
            ) : (
                <CancelIcon
                    sx={{
                        color: '#FB8065',
                        paddingRight: '1vw'
                    }}
                />
            )}
        </InputAdornment>
    );

    const handleChange= (event) => {
        const selectedValue= event.target.value;
        setValue(selectedValue);
    }

    return (
        <Select
            select
            fullWidth
            displayEmpty
            value={value}
            className={`custom-select-input ${additionalClassName} ${isValid !== null && isValid ? 'valid' : isValid === false ? 'invalid' : ''}`}
            sx={{
                backgroundColor: '#f4f7f6',
                borderRadius: { xs: '30px', sm: '0.6vw'},
                marginTop: { xs: '5vw', sm: '1vw'},
                fontFamily: 'Viga',
                height: { xs: '10vw', sm: '2.5vw'},
                fontSize: {xs: '3.2vw', sm: '1vw'},
                paddingLeft: {xs: '3vw', sm: '0.6vw'},
                boxShadow: '0.1vw 0.1vw 0.4vw 0px rgba(179,179,179,0.54)',
                ...customStyles,
                '& .MuiSvgIcon-root': {
                    fontSize: {xs: '5vw', sm: '1.5vw'},
                },
                '& .MuiInputAdornment-root':{
                    marginRight: {xs: '3vw', sm: '0.3vw'}
                },
                '& .MuiOutlinedInput-notchedOutline':{
                    borderColor: "#f4f7f6"
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline':{
                    borderColor: "#5452E1"
                },
                '&:hover  .MuiOutlinedInput-notchedOutline':{
                    borderColor: "#5452E1"
                },
                '& .MuiInputBase-inputAdornedEnd': {
                    paddingRight: '0 !important',
                },

            }}
            InputProps={{
                style: {
                    fontFamily: 'Viga'
                },
            }}
            endAdornment={hideValidation ? null : validationIcon}
            startAdornment={startAdornment}
            onChange={onChange ? onChange : handleChange}
            renderValue={
                value !== '' ?
                    () => options.find(option => option.value === value)?.label || '' :
                    () => <span style={{ color: '#999' }}>{placeholder}</span>
            }
        >
            {options.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                    {option.label}
                </MenuItem>
            ))}
        </Select>
    );
};

export default CustomSelectInput;