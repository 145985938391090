import React, {useEffect, useState} from 'react';
import { Container} from "@mui/material";
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import CustomInput from "../components/CustomInput/CustomInput";
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { LockOutlined as LockOutlinedIcon } from '@mui/icons-material';
import './Login.css'
import IconoGoogle from "../../images/google-icon.png";
import IconoFacebook from "../../images/facebook-logo.png";
import { useLocation } from 'react-router-dom';
import {toast, ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import LoadingButton from "../components/LoadingButton/LoadingButton";
import axios from "axios";
import { useNavigate } from 'react-router-dom';


function Login() {
    const location = useLocation();
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [cargando, setCargando] = useState(false);

    useEffect(() => {
        // Verifica si venimos de una redirección con estado registrado
        if (location.state?.registered) {
            toast.success("Registro exitoso. Por favor, inicia sesión.", {
                position: "top-center",
                autoClose: 15000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
        if (location.state?.changedPassword) {
            toast.success("¡Esoooo! Cambiaste tu contraseña exitosamente. ¡Ahora ingresa y disfruta de todo lo que Wuuju™ tiene para ti!", {
                position: "top-center",
                autoClose: 15000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }, [location]);

    const handleGoogleLogin = () => {
        // Lógica para iniciar sesión con Google
    };

    const handleFacebookLogin = () => {
        // Lógica para iniciar sesión con Facebook
    };

    const sendAPIRequestRegister = (dataToSend) => {
        return axios.post('https://back.wuuju.co/wuuju/api/login/', dataToSend, {
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                return response;
            })
            .catch(error => {
                console.error('Error al realizar la solicitud:', error);
                throw error;
            });
    };

    function handleSubmit() {
        setCargando(true);

        const dataToSend = {
            email: email,
            password: password,
        };

        sendAPIRequestRegister(dataToSend)
            .then((response) => {
                navigate('/crear-casillero-2');
            })
            .catch((error) => {
                console.error('Error al realizar la solicitud:', error);
                Object.keys(error.response.data).forEach((key) => {
                    // Para cada clave, iterar sobre el array de mensajes de error
                    toast.error(`${error.response.data.detail}`, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                });
            })
            .finally(() => {
                setCargando(false);
            });
    }

    return (
        <>
            <Container maxWidth="xl">
                <ToastContainer />
                <h2 className="login-title">Inicia sesión en tu Wuuju-cuenta♡</h2>

                <CustomInput
                    additionalClassName="input-login"
                    placeholder="Correo electrónico"
                    type="text"
                    startIcon={<PermIdentityOutlinedIcon />}
                    hideValidation= {true}
                    setValue={setEmail}
                />
                <CustomInput
                    additionalClassName="input-login"
                    placeholder="Contraseña"
                    type="password"
                    startIcon={<LockOutlinedIcon />}
                    showPasswordIcon={<Visibility />}
                    hidePasswordIcon={<VisibilityOff />}
                    hideValidation= {true}
                    setValue={setPassword}
                />
                <div className="loading-button">
                <LoadingButton
                    handleSubmit={handleSubmit}
                    loading={cargando}
                    label="Iniciar Sesion"
                    customStyles={{
                        height: {xs: "8vw", sm: "2.3vw"},
                        width: {xs: "75%", sm: "100%"},
                        fontSize: {xs: "3.7vw", sm: "1.2vw"},
                        marginTop: {xs: "4vw", sm: "1.2vw"}, 

                    }
                    }
                />
                </div>
                <div className="linea-conjunto">
                        <div className="line"></div>
                        <div className="letter">Ingresa también con</div>
                        <div className="line"></div>
                </div>

                {/* Botones de inicio de sesión */}

                <div className="login-redes-container">
                    <button className="login-google" onClick={handleGoogleLogin}>
                        <img src={IconoGoogle} alt="Google Logo" className="icono" />
                        Inicia sesión con Google
                    </button>

                    <button className="login-facebook" onClick={handleFacebookLogin}>
                        <img src={IconoFacebook} alt="Facebook Logo" className="icono" />
                        Inicia sesión con Facebook
                    </button>
                </div>

                {/* Texto Inferior*/}
                <div className="texto-inferior-login">
                    <div>
                        <a  className="forgot-password" href="/recuperar-contrasena">
                            ¿Olvidaste tu contraseña?
                        </a>
                    </div>
                    <br></br>
                    
                    <div>
                        <span className="login-undertext">
                            ¿Aún no tienes una Wuuju-cuenta?.<a href="/crear-casillero">¡Regístrate, es gratis!</a>
                        </span>
                    </div>
                </div>
            </Container>
        </>
    );
}

export default Login;
