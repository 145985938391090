import React, { useEffect } from 'react';
import Calculator from "../../../../pages/Calculator/Calculator";
import FinalPrice from "../../../../pages/FinalPrice/FinalPrice";
import { useSection } from '../../../../context/SectionContext';
import './MainContent.css'
import { useDatosFinalPrice } from "../../../../context/DatosFinalPrice";
import Address from '../../../../pages/Addresses/Address';
import LoveBox from "../../../../images/LoveBox.png";
import AddressDetail from '../../../../pages/AddressDetail/AddressDetail';
import Prealerts from '../../../../pages/Prealerts/Prealerts';
import EmptyPage from "../../../../pages/EmptyPage/EmptyPage";

const MainContent = ({ section }) => {
    const { activeSection, changeSection } = useSection();
    const { datosFinalPrice } = useDatosFinalPrice();

    useEffect(() => {
        if (section) {
            changeSection(section);
        }
    }, []); // El arreglo vacío indica que este efecto se ejecuta solo en el montaje del componente

    const renderForm = () => {
        switch (activeSection) {
            case 'calculator':
                return <Calculator />;
            case 'FinalPrice':
                return <FinalPrice datos={datosFinalPrice} />;
            case 'direcciones':
                return <Address />;
            case 'DetallesDirecciones':
                return <AddressDetail />;
            case 'prealertas':
                return <Prealerts />;
            default:
                return <EmptyPage />;
        }
    };

    const renderImg = () => {
        switch (activeSection) {
            case 'calculator':
                return <img className='calculatorExtra' src="" alt="" />;
            case 'FinalPrice':
                return <img className='calculatorExtra' src="" alt="" />;
            case 'direcciones':
                return <img className='loveBox' src={LoveBox} alt="" />;
            default:
                return <img className='calculatorExtra' src="" alt="" />;
        }
    };

    useEffect(() => {
        if (datosFinalPrice !== null) {
            changeSection('FinalPrice');
        }
    }, [datosFinalPrice]);

    return (
        <div className="main-panel">
            {renderImg()}
            <div className="page-container">
                {renderForm()}
            </div>
        </div>
    );
}

export default MainContent;