import React, { createContext, useContext, useState } from 'react';

const DatosFinalPrice = createContext();

export const useDatosFinalPrice = () => useContext(DatosFinalPrice);

export const DatosFinalPriceContext = ({ children }) => {
    const [datosFinalPrice, setDatosFinalPrice] = useState(null);

    const changeDatosFinalPrice = (datos) => {
        setDatosFinalPrice(datos);
    };

    return (
        <DatosFinalPrice.Provider value={{ datosFinalPrice, changeDatosFinalPrice }}>
            {children}
        </DatosFinalPrice.Provider>
    );
};
