import React, { useState } from 'react';
import {Container, Typography} from "@mui/material";
import './InitialRegister.css'
import CustomInput from "../components/CustomInput/CustomInput"
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify'
import Checkbox from '@mui/material/Checkbox';
import ReCAPTCHA from "react-google-recaptcha";
import LoadingButton from "../components/LoadingButton/LoadingButton";
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import Tooltip from '../components/Tooltip/Tooltip'

function InitialRegister() {
    const [name, setName] = useState('');
    const [isNameValid, setIsNameValid] = useState(null);

    const [lastname, setLastName] = useState('');
    const [isLastNameValid, setIsLastNameValid] = useState(null);

    const [email, setEmail] = useState('');
    const [isEmailValid, setIsEmailValid] = useState(null);

    const [phone, setPhone] = useState('');
    const [isPhoneValid, setIsPhoneValid] = useState(null);

    const [password, setPassword] = useState('');
    const [isPasswordValid, setIsPasswordValid] = useState(null);

    const [confirmPassword, setConfirmPassword] = useState('');
    const [isConfirmPasswordValid, setIsConfirmPasswordValid] = useState(null);

    const [captchaValue, setCaptchaValue] = useState(null);

    const [terminos, setTerminos] = useState('');

    const [cargando, setCargando] = useState(false);

    const navigate = useNavigate();

    function validateEmail(email) {
        return email.includes('@') && email.includes('.') && email.length >= 4;

    }

    function validatePhone(phone) {
        const onlyNumbers = phone.replace(/\D/g, '');
        return onlyNumbers.length >= 10;
    }

    function validatePassword(password) {
        const isTamanoValido = password.length >= 8 && password.length <= 16;
        const hasNumero = /\d/.test(password);
        const hasMayus = /[A-Z]/.test(password);
        const hasMinus = /[a-z]/.test(password);
        const hasEspecial = /[!@#$%^&*_=+;:<>,.?/~\-]/.test(password);
        return isTamanoValido && hasNumero && hasMayus && hasMinus && hasEspecial;
    }

    /* Guardar token de validación de captcha */
    const handleCaptchaChange = (value) => {
        setCaptchaValue(value);
    };

    const sendAPIRequestRegister = (dataToSend) => {
        return axios.post('https://back.wuuju.co/wuuju/api/register-user/', dataToSend, {
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                return response;
            })
            .catch(error => {
                console.error('Error al realizar la solicitud:', error);
                throw error;
            });
    };
    function handleRegister() {
        const isNameValid = /^[a-zA-Z\s]+$/.test(name) && name.length > 0;
        const isLastNameValid = /^[a-zA-Z\s]+$/.test(lastname) && lastname.length > 0;
        const isEmailValid = validateEmail(email);
        const isPhoneValid = validatePhone(phone);
        const isPasswordValid = validatePassword(password);
        const isConfirmPasswordValid = password === confirmPassword && password.length > 0;
    
        setIsNameValid(isNameValid);
        setIsLastNameValid(isLastNameValid);
        setIsEmailValid(isEmailValid);
        setIsPhoneValid(isPhoneValid);
        setIsPasswordValid(isPasswordValid);
        setIsConfirmPasswordValid(isConfirmPasswordValid);
    
        if (!isPasswordValid) {
            const toastMessage = (
                <div>
                    <p>Tu contraseña debe incluir:</p>
                    -Entre 8 a 16 caracteres<br></br>
                    -Al menos una letra mayúscula.<br></br>
                    -Al menos una letra minúscula.<br></br>
                    -Al menos un carácter especial (!@#$%^&*_=+;:,.?/~-) <br></br>
                </div>
        );
            toast.error(toastMessage, {
                position: 'top-center',
                autoClose: 2500,
                style: {
                    fontFamily: 'Viga',
                    fontSize:'12px',
                    top:'15px',
                    height:'130px',
                    marginTop: '1vw', width: '300px', left: '9vw',
                },
            });
        }
    
        if (!isEmailValid) {
            const toastMessage = "El correo electrónico ingresado no es válido. Por favor, verifica.";
            toast.error(toastMessage, {
                position: 'top-center',
                autoClose: 2500,
                style: {
                    fontFamily: 'Viga',
                    fontSize: '12px',
                    top: '10px',
                    height: '70px',
                    width: '300px',
                    left: '9vw',
                },
            });
        }
    
        if (!isPhoneValid) {
            const toastMessage = "El número de teléfono ingresado no es válido, debe contar con 10 dígitos. Por favor, verifica.";
            toast.error(toastMessage, {
                position: 'top-center',
                autoClose: 2500,
                style: {
                    fontFamily: 'Viga',
                    fontSize: '12px',
                    top: '10px',
                    height: '70px',
                    width: '300px',
                    left: '9vw',
                },
            });
        }
    
        if (!isConfirmPasswordValid) {
            const toastMessage = "La contraseña debe coincidir.";
            toast.error(toastMessage, {
                position: 'top-center',
                autoClose: 2500,
                style: {
                    fontFamily: 'Viga',
                    fontSize: '12px',
                    top: '10px',
                    height: '70px',
                    width: '300px',
                    left: '9vw',
                },
            });
        }
    
        if (!name || !email || !phone || !password || !confirmPassword) {
            toast.error("No tan rápido McQueen, por favor llena todos los campos.", {
                position: 'top-center',
                autoClose: 2500,
                style: {
                    fontFamily: 'Viga',
                    fontSize: '12px',
                    top: '10px',
                    height: '70px',
                    width: '300px',
                    left: '9vw',
                },
            });
        } else if (!terminos) {
            toast.error("Debes aceptar nuestros términos y condiciones", {
                position: 'top-center',
                autoClose: 2500,
                style: {
                    fontFamily: 'Viga',
                    fontSize: '12px',
                    top: '10px',
                    height: '70px',
                    width: '300px',
                    left: '9vw',
                },
            });
        } else if (isNameValid && isEmailValid && isPhoneValid && isConfirmPasswordValid) {
            setCargando(true);


            const dataToSend = {
                name: name,
                lastname: lastname,
                email: email,
                phone: phone,
                password: password,
                token: captchaValue,
            };

            sendAPIRequestRegister(dataToSend)
                .then((response) => {
                    navigate('/inicio-sesion', { state: { registered: true } });
                })
                .catch((error) => {
                    console.error('Error al realizar la solicitud:', error);

                    Object.keys(error.response.data).forEach((key) => {
                        // Ejemplo de manejo de errores específicos por clave
                        switch (key) {
                            case 'email':
                                setIsEmailValid(false);
                                break;
                            case 'phone':
                                setIsPhoneValid(false);
                                break;
                            // Agrega más casos según sea necesario
                            default:
                                // Manejo por defecto o para claves no específicamente manejadas
                                break;
                        }

                        // Para cada clave, iterar sobre el array de mensajes de error
                        error.response.data[key].forEach((message) => {
                            toast.error(`${message}`, {
                                style: {
                                    marginTop: '40px',
                                    width: '300px',
                                    left: '-1%',
                                },
                            });
                        });
                    });
                })
                .finally(() => {
                    setCargando(false);
                });
        }
    }
    return (
        <>
            <Container maxWidth="xl">
                <Typography
                    className="main-register-title"
                    variant="h1"
                    sx={{
                        color: '#5452e1',
                        fontFamily: 'Viga, sans-serif',
                        fontSize: {xs: '6vw', sm: '2vw'},
                        textAlign: 'center',
                        lineHeight: 'normal',
                        my: 'auto',
                        marginTop: {xs: '1.5vw', sm: '0.1vw'},
                        marginBottom: 'px',
                    }}
                >
                    Crea tu Wuuju-cuenta♡
                </Typography>
                <ToastContainer position="bottom-right"/>
                <CustomInput
                    customStyles={{
                    }}
                    additionalClassName="inputs-register1"
                    placeholder="Nombre"
                    type="text"
                    startIcon={<PermIdentityOutlinedIcon />}
                    setValue={setName}
                    isValid={isNameValid}
                />

                <CustomInput
                    customStyles={{
                    }}
                    additionalClassName="inputs-register1"
                    placeholder="Apellidos"
                    type="text"
                    startIcon={<PermIdentityOutlinedIcon />}
                    setValue={setLastName}
                    isValid={isLastNameValid}
                />

                <CustomInput
                    customStyles={{
                    }}
                    additionalClassName="inputs-register2"
                    placeholder="Correo electrónico"
                    type="email"
                    startIcon={<EmailOutlinedIcon />}
                    setValue={setEmail}
                    isValid={isEmailValid}
                />

                <CustomInput
                    customStyles={{
                    }}
                    additionalClassName="inputs-register2"
                    placeholder="Número de teléfono"
                    type="number"
                    setValue={setPhone}
                    startIcon={<LocalPhoneOutlinedIcon />}
                    isValid={isPhoneValid}
                />
            <div className = "input-tooltip-info">
                <CustomInput
                    customStyles={{
                    }}
                    additionalClassName="inputs-register2"
                    placeholder="Contraseña"
                    type="password"
                    setValue={setPassword}
                    startIcon={<LockOutlinedIcon />}
                    showPasswordIcon={<Visibility />}
                    hidePasswordIcon={<VisibilityOff />}
                    isValid={isPasswordValid}
                />
                <Tooltip />
            </div>
                <CustomInput
                    customStyles={{
                    }}
                    additionalClassName="inputs-register2"
                    placeholder="Repite tu contraseña"
                    type="password"
                    setValue={setConfirmPassword}
                    startIcon={<LockOutlinedIcon />}
                    showPasswordIcon={<Visibility />}
                    hidePasswordIcon={<VisibilityOff />}
                    isValid={isConfirmPasswordValid}
                />

                <div className="checkbox-conjunto">
                    <Checkbox
                        checked={terminos}
                        onChange={undefined}
                        color="primary"
                        className="checkbox"
                        onClick={() => setTerminos(!terminos)}
                        sx={{
                            padding: {xs: '0', sm: '0.5vw'},
                            '& .MuiSvgIcon-root': {
                                fontSize: {xs: '6vw', sm: '1.5vw'},
                            }
                        }}
                    />
                    <span className="text-checkbox">He leído y estoy de acuerdo con los 
                    <a href="https://wuuju.co/pages/terminos-y-condiciones-del-servicio-wuuju" rel="noreferrer" target="_blank" className="a-checkbox"> términos y condiciones del servicio </a> y la
                        <a className="a-checkbox" href="https://wuuju.co/pages/politica-de-privacidad" rel="noreferrer"  target="_blank"> política de privacidad</a>
                        </span>
                </div>
                <div className="captcha-zone">
                    <ReCAPTCHA
                        sitekey="6Lf8QDkpAAAAAKxxbB7jIPYY_CSHiZs59bPmUB5P"
                        onChange={handleCaptchaChange}
                    />
                </div>
                <div className="buttom-register1-pack">
                    <LoadingButton
                        handleSubmit={handleRegister}
                        loading={cargando}
                        label="Registrarse"
                    />
                    <span className="underbutton-text">
                    ¿Ya tienes una Wuuju-cuenta?.<a href="/inicio-sesion"> Inicia sesión</a>
                </span>
                </div>
            </Container>
        </>
    );
}

export default InitialRegister;
