import React, { useState } from "react";
import "./Tooltip.css";
import Pregunta from "../../../images/Icono_pregunta.png";
import Cerrar from "../../../images/icono_x_verde.png";

const Tooltip = () => {
    const [showTooltip, setShowTooltip] = useState(false);

    const handleImageClick = () => {
    setShowTooltip(!showTooltip);
};

    const handleCloseClick = () => {
    setShowTooltip(false);
};

    return (
        <div className="tooltip-container">
        <img
            className="tooltip-img-open"
            src={Pregunta}
            alt="Show password instructions"
            onClick={handleImageClick}
        />
    {showTooltip && (
        <div className="tooltip-content">
        <img 
            src={Cerrar}
            alt="Cerrar"
            className="close-tooltip"
            onClick={handleCloseClick}
          />
          <h2>Tu contraseña debe incluir</h2>
          <ul>
            <li>-Entre 8 y 16 caracteres</li>
            <li>-Al menos una letra mayúscula.</li>
            <li>-Al menos una letra minúscula</li>
            <li>-Al menos un carácter especial</li>
            <li>-Al menos un número.</li>
           
          </ul>
        </div>
      )}
    </div>
  );
};

export default Tooltip;