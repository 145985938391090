import * as React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateField } from '@mui/x-date-pickers/DateField';
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from '@mui/material/FormControl';
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";

export default function BasicDateField({ placeholder, startIcon, value, setValue, additionalClassName,customStyles, isValid, hideValidation }) {
    const startAdornment = startIcon && (
        <InputAdornment position="start">
            {startIcon}
        </InputAdornment>
    );

    const validationIcon = isValid !== null && (
        <InputAdornment position="end">
            {isValid ? (
                <CheckCircleIcon
                    sx={{
                        color: '#6CE9B1',
                    }}
                    fontSize="small"
                />
            ) : (
                <CancelIcon
                    sx={{
                        color: '#FB8065',
                    }}
                    fontSize="small"
                />
            )}
        </InputAdornment>
    );

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <FormControl fullWidth>
                <DateField
                    value={value}
                    className={additionalClassName}
                    onChange={(newValue) => setValue(newValue)}
                    format="DD-MM-YYYY"
                    sx={{
                        '& .MuiInputBase-root': {
                            backgroundColor: '#f4f7f6',
                            borderRadius: {xs: '30px', sm: '0.6vw'},
                            marginTop: {xs: '5vw', sm: '1vw'},
                            fontFamily: 'Viga',
                            height: {xs: '10vw', sm: '2.5vw'},
                            fontSize: {xs: '3.2vw', sm: '1vw'},
                            paddingLeft: {xs: '3vw', sm: '0.6vw'},
                            boxShadow: '0.1vw 0.1vw 0.4vw 0px rgba(179,179,179,0.54)',
                            ...customStyles, 
                        },
                        '& .MuiSvgIcon-root': {
                            fontSize: {xs: '5vw', sm: '1.5vw'},
                        },
                        '& .MuiInputAdornment-root': {
                            marginRight: {xs: '3vw', sm: '0.5vw'}
                        }
                    }}
                    InputProps={{
                        startAdornment: startAdornment,
                        endAdornment: hideValidation ? null : validationIcon,
                    }}
                    inputProps={{
                        placeholder: placeholder,
                        style: {
                            fontFamily: 'Viga',
                        },
                    }}
                />
            </FormControl>
        </LocalizationProvider>
    );
}

