import React, {useEffect, useState} from 'react';
import {Container} from "@mui/material";
import './NewPassword.css'
import CustomInput from "../components/CustomInput/CustomInput";
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { LockOutlined as LockOutlinedIcon } from '@mui/icons-material';
import ReCAPTCHA from "react-google-recaptcha";
import LoadingButton from "../components/LoadingButton/LoadingButton";
import axios from "axios";
import {toast, ToastContainer} from "react-toastify";
import {useNavigate} from "react-router-dom";
import Tooltip from '../components/Tooltip/Tooltip'


function NewPassword() {
    const [captchaValue, setCaptchaValue] = useState(null);
    const [userId, setUserId] = useState('');
    const [token, setToken] = useState('');
    const [cargando, setCargando] = useState(false);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const [validPassword, setValidPassword] = useState(null);
    const [validConfirm, setValidConfirm] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        setUserId(searchParams.get('ui'));
        setToken(searchParams.get('tk'));
    }, []);

    const handleCaptchaChange = (value) => {
        setCaptchaValue(value);
    };

    function validatePassword(password) {
        const isTamanoValido = password.length >= 8 && password.length <= 16;
        const hasNumero = /\d/.test(password);
        const hasMayus = /[A-Z]/.test(password);
        const hasMinus = /[a-z]/.test(password);
        const hasEspecial = /[!@#$%^&*_=+;:<>,.?/~\-]/.test(password);

        return isTamanoValido && hasNumero && hasMayus && hasMinus && hasEspecial;
    }

    const handleChangePassword = async (event) => {
        if (password !== confirmPassword){
            const toastMessage = "Ups. Las contraseñas no coinciden. Prueba de nuevo."

            toast.error(toastMessage, {
                position: 'top-center',
                autoClose: 2500,
                style: {
                    fontFamily: 'Viga',
                    fontSize:'12px',
                    top:'10px',
                    width: '300px', left: '9vw',
                },
            });
            setValidConfirm(false)
            return;
        }

        if (!validatePassword(password)) {
            const toastMessage = (
                <div>
                    <p>Tu contraseña debe incluir:</p>

                    -Entre 8 a 16 caracteres<br></br>
                    -Al menos una letra mayúscula.<br></br>
                    -Al menos una letra minúscula.<br></br>
                    -Al menos un carácter especial (!@#$%^&*_=+;:,.?/~-).<br></br>

                </div>
            );

            toast.error(toastMessage, {
                position: 'top-center',
                autoClose: 2500,
                style: {
                    fontFamily: 'Viga',
                    fontSize:'12px',
                    top:'10px',
                    width: '300px', left: '9vw',
                },
            });
            setValidPassword(false)
            return;
        }

        setCargando(true);
        try {
            if (!userId || !token) {
                console.error("Falta información necesaria para el cambio de contraseña");
                return;
            }
            const response = await axios.post(`https://back.wuuju.co/wuuju/api/change-password/`, {
                user_id: userId,
                token: token,
                password: password,
                captcha: captchaValue
            }, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.status === 200) {
                console.log(response.data.message);
                navigate('/inicio-sesion', { state: { changedPassword: true } });
            } else {
                const toastMessage = "No se pudo realizar el cambio de contraseña.";

                toast.error(toastMessage, {
                    position: 'top-center',
                    autoClose: 2500,
                    style: {
                        fontFamily: 'Viga',
                        fontSize:'12px',
                        top:'10px',
                        width: '300px', left: '9vw',
                    },
                });
            }
        } catch (error) {
            const toastMessage = "No se logró restablecer la contraseña. Solicita nuevamente la recuperación de contraseña. ";
            toast.error(toastMessage, {
                position: 'top-center',
                autoClose: 2500,
                style: {
                    fontFamily: 'Viga',
                    fontSize:'12px',
                    top:'10px',
                    width: '300px', left: '9vw',
                },
            });

            if (error.response) {
                //console.error(error.response.data.error);
            } else if (error.request) {
                //console.error("No se recibió respuesta del servidor");
            } else {
                //console.error("Error:", error.message);
            }
        } finally {
            setCargando(false);
        }
    };


    return (
        <>
            <Container maxWidth="xl">
                <ToastContainer position="top-center"/>
                <h1 className="title-newpassword">Crea una contraseña segura</h1>
                <p className="parrafo-newpassword">La contraseña debe tener entre ocho y dieciséis caracteres <br></br> e incluir una combinación de números, letras, mayúsculas<br></br> y minúsculas, y caracteres especiales (!$@%*).</p>
                <div className= "input-tooltip-info2">
                <CustomInput
                    additionalClassName="input-newpassword"
                    placeholder="Contraseña"
                    type="password"
                    setValue={setPassword}
                    startIcon={<LockOutlinedIcon />}
                    showPasswordIcon={<Visibility />}
                    hidePasswordIcon={<VisibilityOff />}
                    isValid={validPassword}
                />
                <Tooltip />
                </div>
                <CustomInput
                    additionalClassName=""
                    placeholder="Confirma tu contraseña"
                    type="password"
                    setValue={setConfirmPassword}
                    startIcon={<LockOutlinedIcon />}
                    showPasswordIcon={<Visibility />}
                    hidePasswordIcon={<VisibilityOff />}
                    isValid={validConfirm}
                />

                <div className="captcha-zone-newpassword">
                    <ReCAPTCHA
                        sitekey="6Lf8QDkpAAAAAKxxbB7jIPYY_CSHiZs59bPmUB5P"
                        onChange={handleCaptchaChange}
                    />
                </div>
                <div className="button-newpassword">
                    <LoadingButton
                        handleSubmit={handleChangePassword}
                        loading={cargando}
                        label="Restablecer contraseña"
                        customStyles={{
                            fontSize: {xs: "3.7vw", sm: "1.2vw"},
                            marginTop: {xs: "4vw", sm: "1.2vw"}, }}
                    />
                </div>
                <div className="final-text-newpassword">
                    <span className="newpassword-undertext">¿Recordaste tu contraseña?. <a className="a-text-newpassword" href="/inicio-sesion">Inicia sesión</a></span>
                </div>
            </Container>
        </>
    );
}

export default NewPassword;
