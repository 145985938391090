import React from 'react';
import { Dialog, DialogTitle, DialogContent, Button, IconButton, Grid } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';

const CustomModal = ({open, onClose, title, content, buttonText, onButtonClick, image, sx, buttonText2, onButtonClick2, invertOrder, disableb2 }) => {

  const elements = [
    <DialogTitle key="title" sx={{ ...sx?.title, display: title ? 'block' : 'none' }}>
      {title}
    </DialogTitle>,
    image && (
      <div key="image" style={{ display: image ? 'block' : 'none', position: 'relative' }}>
        <Box
            component="img"
            sx={{
              width: '10vw',
              marginTop: '0.5vw',
              ...sx?.image,
            }}
            alt="Modal Image"
            src={image}
        />
      </div>
    ),
  ];

  const orderedElements = invertOrder ? [...elements].reverse() : elements;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      BackdropProps={{
        style: {
          backdropFilter: 'blur(5px)',
          backgroundColor: 'rgba(0, 0, 0, 0)',
        },
      }}
      PaperProps={{
        sx: {
          width: { xs: '85vw', sm: '40vw' },
          borderRadius: { xs: '4vw', sm: '2vw' },
          overflow: 'hidden',
          alignItems: 'center',
          ...sx?.paper,
        },
      }}
    >
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{ position: 'absolute', right: '4px', top: '4px', ...sx?.closeButton }}
      >
        <CloseIcon />
      </IconButton>

      {orderedElements}

      <DialogContent sx={{ ...sx?.content }}>
        {content}
      </DialogContent>

      <Grid sx={{ display: 'flex', gap: '2vw', ...sx?.gridbutton }}>
        <Button onClick={onButtonClick} sx={{ ...sx?.button }}>
          {buttonText}
        </Button>
        {buttonText2 && onButtonClick2 && (
          <Button onClick={onButtonClick2} sx={{ ...sx?.button2 }} disabled={disableb2}>
            {buttonText2}
          </Button>
        )}
      </Grid>
    </Dialog>
  );
};

export default CustomModal;
