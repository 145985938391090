import React from 'react';
import './RegisterMainContent2.css'
import SecondaryRegister from "../../../../pages/SecondaryRegister/SecondaryRegister";
import Login from "../../../../pages/Login/Login";
import RestorePassword from "../../../../pages/RestorePassword/RestorePassword";


const RegisterMainContent2 = ({section}) => {
    return (
    
        <div className="main-panel-register-2">
        <div className="page-container-register-2">
          {section === 'login' ? (
            <Login />
          ) : section === 'register-2' ? (
            <SecondaryRegister />
          ) : section === 'restore-password' ? (
            <RestorePassword/>
          ) : null}
        </div>
      </div>
      
    );
};

export default RegisterMainContent2;