import React from 'react';
import './RegisterLayout1.css';
import LayoutBase from "../LayoutBase";
import RegisterMessage from "./components/RegisterMessage/RegisterMessage";
import RegisterMainContent from "./components/RegisterMainContent/RegisterMainContent"
import NewPasswordMessage from "./components/NewPasswordMessage/NewPasswordMessage";

function RegisterLayout1({ section }) {
    // Determinar el componente adicional basado en la sección
    const additionalComponent = section === 'new-password' ? <NewPasswordMessage /> : <RegisterMessage />;

    return (
        <LayoutBase
            sidebarComponent={null}
            mainContent={<RegisterMainContent section={section} />}
            additionalComponent={additionalComponent}
            additionalClass="register1-layout"
        />
    );
}


export default RegisterLayout1;
