import React, {useEffect, useState} from 'react';
import './NavigationBar.css';
import Menu from '../../../../images/Boton_menu.png';
import Perfil from '../../../../images/User_off.png';
import Direcciones from '../../../../images/Address_off.png';
import Prealertas from '../../../../images/Alert_Off.png';
import Paquetes from '../../../../images/Box_off.png';
import Pagos from '../../../../images/Payment_off.png';
import Calculadora from '../../../../images/Calculator_off.png';
import Close from '../../../../images/Boton_minimizar_menu.png';
import PerfilActivado from '../../../../images/Perfil_menu_activado.png';
import DireccionesActivado from '../../../../images/Direccion_menu_activado.png';
import PrealertasActivado from '../../../../images/Prealertas_menu_activado.png';
import PaquetesActivado from '../../../../images/Paquetes_menu_activado.png';
import PagosActivado from '../../../../images/PAGO_MENU_ACTIVADO.png';
import CalculadoraActivado from '../../../../images/Calculadora_menu_activo.png';
import {useSection} from "../../../../context/SectionContext";
import {useAuth} from "../../../../hooks/useAuth";

const MobileNavbar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const { activeSection, changeSection } = useSection();
    const [selectedIcon, setSelectedIcon] = useState(null);
    const isAuthenticated = useAuth();

    // Idetifica qué icono resaltar por sección activa
    useEffect(() => {
        switch (activeSection) {
            case 'calculator':
                setSelectedIcon('calculator');
                break;
            case 'FinalPrice':
                setSelectedIcon('calculator');
                break;
            case 'direcciones':
                setSelectedIcon('direcciones');
                break;
            case 'DetallesDirecciones':
                setSelectedIcon('direcciones');
                break;
            case 'prealertas':
                setSelectedIcon('prealertas');
                break;
            default:
                setSelectedIcon(null)
        }
    }, [activeSection]);

    const navigationMovilItems = [
        { index: 'profile', image: Perfil, imageActive: PerfilActivado, text: 'Perfil' },
        { index: 'direcciones', image: Direcciones, imageActive: DireccionesActivado, text: 'Direcciones' },
        { index: 'prealertas', image: Prealertas, imageActive: PrealertasActivado, text: 'Prealertas' },
        { index: 'paquetes', image: Paquetes, imageActive: PaquetesActivado, text: 'Paquetes' },
        { index: 'pagos', image: Pagos, imageActive: PagosActivado, text: 'Pagos' },
        { index: 'calculator', image: Calculadora, imageActive: CalculadoraActivado, text: 'Calculadora' },
    ];


    const NavItems = ({ index, image, imageActive, text }) => {
        // Define las secciones desactivadas
        const [disabledSections, setDisabledSections] = useState(['paquetes', 'profile', 'pagos']);

        useEffect(() => {
            if (!isAuthenticated) {
                setDisabledSections(currentDisabledSections => [...currentDisabledSections, 'direcciones', 'prealertas']);
            }
        }, [isAuthenticated]);

        // Verifica si la sección está desactivada
        const isDisabled = disabledSections.includes(index);

        // Agrega la clase 'disabled' si la sección está desactivada
        const itemClassName = `${selectedIcon === index ? 'active' : ''} ${isDisabled ? 'disabled' : ''}`

        // Solo permite cambiar la sección si no está desactivada
        const handleClick = () => {
            if (!isDisabled) {
                changeSection(index);
                setIsOpen(false);
            }
        };

        return (
            <a
                onClick={handleClick}
                className={itemClassName}
            >
                <img
                    src={selectedIcon === index ? imageActive : image}
                    alt={text}
                    className="img-navbar"
                />
                <span>{text}</span>
            </a>
        );
    };

    const toggleNavbar = () => {
        setIsOpen(!isOpen);
    };

    return (

        <div id="navbar-movil">
            {isOpen && <div className="backdrop" onClick={toggleNavbar}></div>}
            <div className="navbar-buttom">
                <div className="menu-icon-text">
                    <img
                        src={Menu}
                        alt="Menu"
                        className="menu-icon"
                        onClick={toggleNavbar}
                    />
                    <span className="menu-text">Menú</span>
                </div>
            </div>
            <div id="nav-bar-content">
                <div className={`nav-bar ${isOpen ? 'open' : ''}`}>
                    <div className="navbar-close">
                        <img
                            src={Close}
                            alt="Cerrar"
                            className="img-close-navbar"
                            onClick={toggleNavbar}
                        />
                    </div>
                    <div className="nav-links">
                        {navigationMovilItems.map((item, index) => (
                            <NavItems key={index} index={item.index} image={item.image} text={item.text} imageActive={item.imageActive} />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MobileNavbar;