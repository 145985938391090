import React from 'react';
import './RegisterMainContent.css'
import InitialRegister from "../../../../pages/InitialRegister/InitialRegister";
import NewPassword from '../../../../pages/NewPassword/NewPassword';

const RegisterMainContent = ({section}) => {
    return (
        <div className="main-panel-register">
            <div className="page-container-register-1">
            {section === 'register-1'? (
            <InitialRegister/>
          ) : section === 'new-password' ? (
            <NewPassword />
          ) : null}
            </div>
        </div>
    );
};

export default RegisterMainContent;