import React, {useState, useEffect} from 'react';
import { Container, Grid, Typography } from "@mui/material";
import CustomInput from '../components/CustomInput/CustomInput';
import InputSelect from "../components/CustomInputSelect/InputSelect";
import { Badge, Pin, Person, Place, Storefront, ShoppingCart, Discount, Search, CalendarMonth } from "@mui/icons-material";
import Checkbox from '@mui/material/Checkbox';
import DatePicker from "../components/DateField/DateField";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./SecondaryRegister.css";
import LoadingButton from "../components/LoadingButton/LoadingButton";
import {opcionesDepartamentos} from "../components/opcionesDepartamentos";
import CustomInputAutocompleteMulti from "../components/CustomInputAutocompleteMulti/CustomInputAutocompleteMulti";
import CustomAutocomplete from "../components/CustomInputAutocomplete/CustomInputAutocomplete";
import { useAuth } from '../../hooks/useAuth';
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import { useDatosUser} from "../../context/userContext";

function SecondaryRegister() {
    const [tipoIdentificacion, setTipoIdentificacion] = useState('');
    const [identificacion, setIdentificacion] = useState('');
    const [fechaNacimiento, setFechaNacimiento] = useState(null);
    const [genero, setGenero] = useState('');
    const [direccion, setDireccion] = useState('');
    const [departamento, setDepartamento] = useState('');
    const [ciudad, setCiudad] = useState('');
    const [ciudadesFiltradas, setCiudadesFiltradas] = useState([]);
    const [mayorista, setMayorista] = useState("");
    const [areaComercial, setAreaComercial] = useState([]);
    const [codigoReferido, setCodigoReferido] = useState('');
    const [origenReferencia, setOrigenReferencia] = useState('');
    const [publicidad, setPublicidad] = useState(true);

    const [isTipoIdentificacionValid, setIsTipoIdentificacionValid] = useState(null);
    const [isIdentificacionValid, setIsIdentificacionValid] = useState(null);
    const [isFechaNacimientoValid, setIsFechaNacimientoValid] = useState(null);
    const [isGeneroValid, setIsGeneroValid] = useState(null);
    const [isDireccionValid, setIsDireccionValid] = useState(null);
    const [isDepartamentoValid, setIsDepartamentoValid] = useState(null);
    const [isCiudadValid, setIsCiudadValid] = useState(null);
    const [isMayoristaValid, setIsMayoristaValid] = useState(null);
    const [isAreaComercialValid, setIsAreaComercialValid] = useState(null);
    const [isCodigoReferidoValid, setIsCodigoReferidoValid] = useState(null);
    const [isOrigenReferenciaValid, setIsOrigenReferenciaValid] = useState(null);

    const [cargando, setCargando] = useState(false);
    const navigate = useNavigate();
    const isAuthenticated = useAuth();
    const { completed } = useDatosUser();

    useEffect(() => {
        if (isAuthenticated === null) {
            // En espera
        } else if (isAuthenticated === false) {
            navigate('/inicio-sesion');
        } else if (completed) {
            navigate('/direcciones');
        }
    }, [isAuthenticated, completed, navigate]);

    if (isAuthenticated === null) {
        return <div>Cargando...</div>;
    }

    function handleTipoIdentificacionChange(event) {
        const value = event.target.value;
        setTipoIdentificacion(value);
        setIsTipoIdentificacionValid(null);
    }

    function handleGeneroChange(event) {
        const value = event.target.value;
        setGenero(value);
        setIsGeneroValid(null);
    }

    const handleDepartamentoChange = (event) => {
        const departamentoSeleccionado = event.target.value;
        const departamento = opcionesDepartamentos.find((dep) => dep.value === departamentoSeleccionado);
        setCiudadesFiltradas(departamento ? departamento.ciudades : []);
        setDepartamento(event.target.value);
        setIsDepartamentoValid(null);
    };
    function handleCiudadChange(event) {
        const value = event.target.value;
        setCiudad(value);
        setIsCiudadValid(null);
    }
    function handleMayoristaChange(event) {
        const value = event.target.value;
        setMayorista(value);
        setIsMayoristaValid(null);
    }

    function handleOrigenReferenciaChange(event) {
        const value = event.target.value;
        setOrigenReferencia(value);
        setIsOrigenReferenciaValid(null);
    }

    const validateSelectFile = (value) => {
        return value !== '';
    };

    const validateNumericInput = (value) => {
        return /^\d+$/.test(value);
    };

    const validateFilledInput = (value) => {
        return value !== null && value !=='';
    };

    const validateAreaComercial = (value) => {
        return value.length > 1;
    };

    const sendAPIRequestRegister = (dataToSend) => {
        return axios.patch('https://back.wuuju.co/wuuju/api/register-user-2/', dataToSend, {
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => {

            })
            .catch(error => {
                console.error('Error al realizar la solicitud:', error);
                throw error;
            });
    };

    const handleStart = () => {
        console.log({areaComercial})
        if (
            !validateSelectFile(tipoIdentificacion) ||
            !validateNumericInput(identificacion) ||
            !validateFilledInput(fechaNacimiento) ||
            !validateSelectFile(genero) ||
            !validateFilledInput(direccion) ||
            !validateSelectFile(departamento) ||
            !validateSelectFile(ciudad) ||
            !validateSelectFile(mayorista) ||
            !validateAreaComercial(areaComercial) ||
            !validateSelectFile(origenReferencia)
        ) {
            toast.error("No tan rápido McQueen, por favor llena todos los campos.", {
                position: 'top-center',
                autoClose: 2500,
                style: {
                    fontFamily: 'Viga',
                    fontSize: '12px',
                    top: '10px',
                    height: '70px',
                    marginTop: '40px',
                    width: '300px',
                    left: '-45%',
                },
            });
            setIsTipoIdentificacionValid(validateSelectFile(tipoIdentificacion));
            setIsIdentificacionValid(validateNumericInput(identificacion));
            setIsFechaNacimientoValid(validateFilledInput(fechaNacimiento));
            setIsGeneroValid(validateSelectFile(genero));
            setIsDireccionValid(validateFilledInput(direccion));
            setIsDepartamentoValid(validateSelectFile(departamento));
            setIsCiudadValid(validateSelectFile(ciudad));
            setIsMayoristaValid(validateSelectFile(mayorista));
            setIsAreaComercialValid(validateAreaComercial(areaComercial));
            setIsOrigenReferenciaValid(validateSelectFile(origenReferencia));
        } else {
            setCargando(true)
            const dataToSend = {
                documentType: tipoIdentificacion,
                cedula: identificacion,
                birthdate: fechaNacimiento.format("YYYY-MM-DD"),
                gender: genero,
                address: direccion,
                city: ciudad,
                mayorista: mayorista,
                interest: areaComercial,
                codigoReferido: codigoReferido,
                origin: origenReferencia,
                publicity: publicidad
            }
            console.log('Formulario válido. Realizando registro...');
            sendAPIRequestRegister(dataToSend)
                .then((response) => {
                    navigate('/direcciones', { state: { registered: true } });
                })
                .catch((error) => {
                    console.error('Error al realizar la solicitud:', error);

                    Object.keys(error.response.data).forEach((key) => {
                        // Ejemplo de manejo de errores específicos por clave
                        switch (key) {
                            case 'cedula':
                                setIsIdentificacionValid(false)
                                break;
                            case 'birthdate':
                                setIsFechaNacimientoValid(false)
                                break;
                            case 'gender':
                                setIsGeneroValid(false)
                                break;
                            case 'mayorista':
                                setIsMayoristaValid(false)
                                break;
                            case 'interest':
                                setIsAreaComercialValid(false)
                                break;
                            case 'codigoReferido':
                                setIsCodigoReferidoValid(false)
                                break;
                            case 'origin':
                                setIsOrigenReferenciaValid(false)
                                break;
                            default:
                                // Manejo por defecto o para claves no específicamente manejadas
                                break;
                        }

                        // Para cada clave, iterar sobre el array de mensajes de error
                        error.response.data[key].forEach((message) => {
                            toast.error(`${message}`, {
                                style: {
                                    marginTop: '40px',
                                    width: '300px',
                                    left: '-1%',
                                },
                            });
                        });
                    });
                })
                .finally(() => {
                    setCargando(false);
                });
        }
    };
    const identificationOptions = [
        { value: 1, label: 'Cédula' },
        { value: 2, label: 'Tarjeta de Identidad' },
        { value: 3, label: 'Cédula de Extranjeria' },
        { value: 4, label: 'Pasaporte' },
        { value: 5, label: 'Permiso de Permanencia' },
    ];

    const generoOptions = [
        { value: 'M', label: 'Masculino' },
        { value: 'F', label: 'Femenino' },
        { value: 'O', label: 'Otro'}
    ];

    const mayoristaOptions = [
        { value: true, label: 'Sí' },
        { value: false, label: 'No' },
    ];
    const areaComercialOptions = [
        { value: 'celulares', label: 'Celulares' },
        { value: 'tablets', label: 'Tablets' },
        { value: 'computadores', label: 'Computadores' },
        { value: 'zapatos', label: 'Zapatos' },
        { value: 'ropa', label: 'Ropa' },
        { value: 'medicamentos', label: 'Medicamentos' },
        { value: 'juguetes', label: 'Juguetes' },
        { value: 'videojuegos', label: 'Videojuegos' },
        { value: 'tecnologia', label: 'Tecnología' },
        { value: 'electrodomesticos', label: 'Electrodomésticos' },
        { value: 'perfumeria', label: 'Perfumería' },
        { value: 'alimentos', label: 'Alimentos' },
        { value: 'respuestos', label: 'Repuestos' },
        { value: 'instrumentos', label: 'Instrumentos Musicales' },
        { value: 'muebles', label: 'Muebles' },
        { value: 'libros', label: 'Libros' },
        { value: 'camaras', label: 'Cámaras' },
        { value: 'drones', label: 'Drones' },
        { value: 'sonido', label: 'Sonido' },
    ];
    const comoNosConocisteOptions = [
        { value: 1, label: 'Facebook' },
        { value: 2, label: 'Instagram' },
        { value: 3, label: 'TikTok' },
        { value: 4, label: 'Google' },
        { value: 5, label: 'Recomendación de un influencer' },
        { value: 6, label: 'Referencia de persona conocida' },
        { value: 7, label: 'Otro' },
    ];

    return (
        <Container maxWidth="xl" className="SecondaryRegister">
            <Typography
                className="custom-title-new"
                variant="h1"
                sx={{
                    color: '#5452e1',
                    fontFamily: 'Viga, sans-serif',
                    fontSize: { xs: '8vw', sm: '2vw'},
                    textAlign: 'center',
                    lineHeight: 'normal',
                    my: 'auto',
                    marginTop: '1px',
                    marginBottom: { xs: '3.5vw', sm: '1vw' },
                }}
            >
                Completa tus datos y listooo
            </Typography>
            <Grid container spacing={0.7} alignItems="center">
                <Grid item xs={12} sm={6}>
                    <InputSelect
                        placeholder="Tipo de identificación"
                        options={identificationOptions}
                        value={tipoIdentificacion}
                        onChange={handleTipoIdentificacionChange}
                        startIcon={<Badge />}
                        isValid={isTipoIdentificacionValid}
                        customStyles={{
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <CustomInput
                        placeholder="Número de identificación"
                        value={identificacion}
                        setValue={setIdentificacion}
                        startIcon={<Pin />}
                        isValid={isIdentificacionValid}
                        type="text"
                    />
                </Grid>
            </Grid>
            <Grid container spacing={0.7} alignItems="center">
                <Grid item xs={12} sm={6}>
                    <DatePicker
                        value={fechaNacimiento}
                        setValue={setFechaNacimiento}
                        startIcon={<CalendarMonth />}
                        isValid={isFechaNacimientoValid}
                        placeholder="Fecha de Nacimiento"
                    />
                </Grid>
                
                <Grid item xs={12} sm={6}>
                    <InputSelect
                        placeholder="Género"
                        options={generoOptions}
                        startIcon={<Person />}
                        value={genero}
                        onChange={handleGeneroChange}
                        isValid={isGeneroValid}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={0.7} alignItems="center">
                <Grid item xs={12} sm={12}>
                    <CustomInput
                        placeholder="Dirección de entrega a Colombia"
                        value={direccion}
                        setValue={setDireccion}
                        startIcon={<Place />}
                        isValid={isDireccionValid}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={0.7} alignItems="center">
                <Grid item xs={12} sm={6}>
                    <CustomAutocomplete
                        placeholder="Departamento"
                        value={departamento}
                        onChange={handleDepartamentoChange}
                        options={opcionesDepartamentos}
                        startIcon={<Place />}
                        isValid={isDepartamentoValid}
                        customStyles={{
                            
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <CustomAutocomplete
                        placeholder="Ciudad"
                        value={ciudad}
                        onChange={handleCiudadChange}
                        options={ciudadesFiltradas}
                        startIcon={<Place />}
                        isValid={isCiudadValid}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={0.7} alignItems="center">
                <Grid item xs={12} sm={6}>
                    <InputSelect
                        placeholder="¿Eres mayorista?"
                        value={mayorista}
                        onChange={handleMayoristaChange}
                        options={mayoristaOptions}
                        startIcon={<Storefront />}
                        isValid={isMayoristaValid}
                        customStyles={{
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <CustomInputAutocompleteMulti
                        placeholder="Me interesa"
                        value={areaComercial}
                        setValue={setAreaComercial}
                        options={areaComercialOptions}
                        startIcon={<ShoppingCart />}
                        isValid={isAreaComercialValid}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={0.7} alignItems="center">
                <Grid item xs={12} sm={6}>
                    <CustomInput
                        placeholder="Código de Referido"
                        value={codigoReferido}
                        setValue={setCodigoReferido}
                        startIcon={<Discount />}
                        isValid={isCodigoReferidoValid}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <InputSelect
                        placeholder="¿Cómo nos conociste?"
                        value={origenReferencia}
                        onChange={handleOrigenReferenciaChange}
                        options={comoNosConocisteOptions}
                        startIcon={<Search />}
                        isValid={isOrigenReferenciaValid}
                    />
                </Grid>
            </Grid>
            <ToastContainer />
            <Grid item xs={16} sx={{ paddingTop: '0.7vw' }}>
                <Grid container spacing={1} alignItems="center">
                    <Grid item xs={1}>
                        <Checkbox
                            checked={publicidad}
                            onChange={undefined}
                            color="primary"
                            className="checkbox"
                            onClick={() => setPublicidad(!publicidad)}
                            sx={{
                                padding: '0',
                                '& .MuiSvgIcon-root': {
                                    fontSize: {xs: '3vw', sm: '1.5vw'},
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={11}>

                        <Typography
                            sx={{
                                color: '#5452e1',
                                fontFamily: 'Viga, sans-serif',
                                fontSize: { xs: '2.3vw', sm: '0.87vw'},
                                paddingTop: {xs: '3vw', sm: '0'},
                                marginLeft: {sm: '-1vw', md:'-1.2vw', lg:'-1.4vw', xl:'-1.7vw'}, 
                            }}
                        >
                            <span className="custom-text">
                                Si quiero recibir códigos promocionales y ofertas por correo electrónico y/o WhatsApp
                            </span>
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sx={{ paddingTop: '2vw' }}>
                <LoadingButton
                    handleSubmit={handleStart}
                    label="Que empiece esto"
                    className="start-button"
                    loading={cargando}
                    />
            </Grid>
        </Container>
    );
}
export default SecondaryRegister;
