import React, { createContext, useContext, useState } from 'react';

const SectionContext = createContext();

export const useSection = () => useContext(SectionContext);

export const SectionProvider = ({ children }) => {
    const [activeSection, setActiveSection] = useState('default');
    const [optionalVariable, setOptionalVariable] = useState(null);

    const changeSection = (section, optionalVar = null) => {
        setActiveSection(section);
        if (optionalVar !== null) {
            setOptionalVariable(optionalVar);
        }
    };

    return (
        <SectionContext.Provider value={{ activeSection, changeSection, optionalVariable }}>
            {children}
        </SectionContext.Provider>
    );
};