import React from 'react';
import './RegisterMessage2.css'
import Cart_box_send from "../../../../images/Cart_box_send.png";
const RegisterMessage2 = () => {
    return (
        <div>
            <div className="register-message-2">
                <div className='message-container-2'>
                    <p>
                        <span className='text-message-2'>Realiza tus compras en USA y recíbelas en la puerta de tu casa.
                        </span> <br /><span className='text-message-2-yellow'> Sin costos ocultos ni gastos extra. </span>
                    </p>
                </div>
            </div>
            <img className='meesage-image' src={Cart_box_send} alt="carro de compras" />
        </div>
    );
};

export default RegisterMessage2;
