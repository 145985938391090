import React, {useState} from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import InputAdornment from '@mui/material/InputAdornment';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

const CustomAutocomplete = ({ placeholder, value, startIcon, options, onChange, isValid, additionalClassName, customStyles, hideValidation }) => {
    const [inputValue, setInputValue] = useState('');

    // Encuentra la opción que coincide con el valor o usa null si no hay coincidencia
    const selectedOption = options.find(option => option.value === value) || null;

    const startAdornment = startIcon && (
        <InputAdornment position="start">
            {startIcon}
        </InputAdornment>
    );

    const validationIcon = isValid !== null && (
        <InputAdornment position="end">
            {isValid ? < CheckCircleIcon style={{
                color: '#6CE9B1',
                paddingRight: '3vw'
            }}
                                         fontSize="small"
            /> : <CancelIcon style={{
                color: '#FB8065',
                paddingRight: '3vw'
            }}
                             fontSize="small"  />}
        </InputAdornment>
    );

    return (
        <Autocomplete
            fullWidth
            displayEmpty
            value={selectedOption}
            onChange={(event, newValue) => {
                onChange({ target: { value: newValue ? newValue.value : '' } });
            }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            options={options}
            noOptionsText="No hay opciones disponibles"
            getOptionLabel={(option) => option.label || ''}
            sx={{
                backgroundColor: '#f4f7f6',
                marginTop: { xs: '5vw', sm: '1vw'},
                borderRadius: { xs: '30px', sm: '0.6vw'},
                height: { xs: '10vw', sm: '2.5vw'},
                ...customStyles,
                '& .MuiSvgIcon-root': {
                    fontSize: {xs: '5vw', sm: '1.5vw'},
                },
                '& .MuiInputBase-root':{
                    height: { xs: '10vw', sm: '2.5vw'},
                    borderRadius: { xs: '30px', sm: '0.6vw'},
                    paddingLeft: {xs: '3vw', sm: '0.6vw'},
                    boxShadow: '0.1vw 0.1vw 0.4vw 0px rgba(179,179,179,0.54)',
                    fontFamily: 'Viga',
                    fontSize: {xs: '3.2vw', sm: '1vw'},
                },
                '& .MuiAutocomplete-endAdornment':{
                    // Para alinear la fecha al centro
                    top: 'auto'
                },
                '& .MuiInputAdornment-root':{
                    // Para pegarlo a izquierda
                    marginRight: '0'
                },
                '&.MuiAutocomplete-root .MuiOutlinedInput-root':{
                    // Para que no corte el texto desde la derecha
                    paddingRight: '0'
                },
                '&.MuiAutocomplete-root .MuiAutocomplete-input':{
                    // Para ajustar texto igual a los demás inputs
                    paddingLeft: {xs: '3vw', sm: '0.6vw'},
                },
                '&.MuiAutocomplete-hasPopupIcon .MuiAutocomplete-inputRoot':{
                    paddingRight: '0',
                },
                '&.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiOutlinedInput-root':{
                    paddingRight: '0',
                }
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    placeholder={(!inputValue && !value) ? placeholder : ''}
                    variant="outlined"
                    InputProps={{
                        ...params.InputProps,
                        style: {
                            fontFamily: 'Viga',
                            fontSize: {xs: '3.2vw', sm: '1vw'},
                        },
                        startAdornment: startAdornment,
                        className: additionalClassName,
                        endAdornment: (
                            <React.Fragment>
                                {hideValidation ? null : validationIcon}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
        />
    );
};

export default CustomAutocomplete;
