import React from 'react';
import './Message.css'
import alerta from "../../../images/alerta.png";
const RegisterMessage2 = () => {
    return (
        <div>
            <div className="register-message-2-1">
                <div className='message-container-2-1'>
                    <p>
                       <span>Recuerda adjuntar la <br /><span className='orange-text'>factura original </span> de tu <br /> compra.</span>
                    </p>
                </div>
                <img className='alerta-imagen-prealert' src={alerta} alt="" />
            </div>
         
        </div>
    );
};

export default RegisterMessage2;
