import React from 'react';
import candado from '../../../../images/cancado_v2.png'
import "./NewPasswordMessage.css"

const NewPasswordMessage = () => {
    return (
        <div>
            <div className="register-message">
                <h2 className="new-password-message-text">Crea una nueva<br/> que no se te vuelva a 
                    <br/>olvidar, que puedas<br/> recordar fácil<br/> y que no sea tan obvia.
                    <span className="recommendations-text"><br/> No uses tu nombre,<br/> tu número
                    <br/> de identificación <br/>o de teléfono</span></h2>
            </div>
            <img className ="new-password-img" src={candado} alt=""></img>
        </div>
    );
};

export default NewPasswordMessage;