import React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import InputAdornment from "@mui/material/InputAdornment";
import {Checkbox} from "@mui/material";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";

export default function MultiSelectDropdown({ placeholder, startIcon, options, customStyles, isValid, hideValidation, value, setValue }) {
    const startAdornment = startIcon && (
        <InputAdornment position="start">
            {startIcon}
        </InputAdornment>
    );

    const handleChange = (event, newValue) => {
        // Extrae solo los valores de las opciones seleccionadas
        const values = newValue.map(option => option.value);
        setValue(values);
    };

    const validationIcon = isValid !== null && (
        <InputAdornment position="end">
            {isValid ? (
                <CheckCircleIcon
                    sx={{
                        color: '#6CE9B1',
                        paddingRight: '1vw'
                    }}
                />
            ) : (
                <CancelIcon
                    sx={{
                        color: '#FB8065',
                        paddingRight: '1vw'
                    }}
                />
            )}
        </InputAdornment>
    );

    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;

    return (
            <Autocomplete
                multiple
                limitTags={2}
                id="tags-standard"
                options={options}
                renderOption={(props, option, { selected }) => (
                    <li {...props}>
                        <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                        />
                        {option.label}
                    </li>
                )}
                onChange={handleChange}
                value={options.filter(option => value.includes(option.value))}
                getOptionLabel={(option) => option.label}
                sx={{
                    backgroundColor: '#f4f7f6',
                    marginTop: { xs: '5vw', sm: '1vw'},
                    borderRadius: { xs: '30px', sm: '0.6vw'},
                    height: { xs: '10vw', sm: '2.5vw'},
                    boxShadow: '0.1vw 0.1vw 0.4vw 0px rgba(179,179,179,0.54)',
                    ...customStyles,
                    '& .MuiSvgIcon-root': {
                        fontSize: {xs: '5vw', sm: '1.5vw'},
                    },
                    '& .MuiInputBase-root': {
                        height: { xs: '10vw', sm: '2.5vw'},
                        borderRadius: { xs: '30px', sm: '0.6vw'},
                        paddingLeft: {xs: '3vw', sm: '0.6vw'},
                        fontFamily: 'Viga',
                        fontSize: {xs: '3.2vw', sm: '1vw'},
                    },
                    '& .MuiAutocomplete-tag': {
                        maxWidth: '100%', // Asegurar que los chips no excedan el ancho del campo
                    },
                    '.MuiAutocomplete-inputRoot':{
                        paddingRight: '1vw'
                    },
                    '& .MuiAutocomplete-inputRoot[class*="MuiInput-root"] .MuiAutocomplete-input': {
                        minWidth: '30px', // Mantener espacio para al menos un chip
                        flexWrap: 'nowrap', // Prevenir el salto de línea de chips
                        overflow: 'hidden', // Ocultar overflow horizontalmente
                    },
                    '& .MuiInput-root::before, .MuiInput-root::after':{
                        borderBottom: '0',
                    },
                    '& .MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before':{
                        borderBottom: '0',
                    },
                    '&.MuiAutocomplete-hasPopupIcon .MuiAutocomplete-inputRoot':{
                        paddingRight: '0',
                    },
                    '&.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiOutlinedInput-root':{
                        paddingRight: '0',
                    }
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="standard"
                        placeholder={placeholder}
                        InputProps={{
                            ...params.InputProps,
                            style: { flexWrap: 'nowrap' },
                            startAdornment: (
                                <>
                                    {startAdornment}
                                    {params.InputProps.startAdornment}
                                </>
                            ),
                            endAdornment: hideValidation ? null : validationIcon
                        }}
                        sx={{
                            overflow: 'hidden'
                        }}
                    />
                )}
            />
    );
}
